import React, {useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody, DataTableCell
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {TextField} from '@rmwc/textfield';
import {Checkbox} from '@rmwc/checkbox';
import ReportsApi from "../../../api/reportsApi";
import {CSVLink} from 'react-csv';

const Report = () => {
  const [open, setOpen] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [blocked, setBlocked] = useState(true);
  const [checked, setChecked] = useState(false);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [labelReport, setLabelReport] = useState('');
  const [listReport, setListReport] = useState([]);
  const [ListHeads, setListHeads] = useState([]);
  const [listSowns, setListSonws] = useState([]);
  const [listHarvest, setListHarvest] = useState([]);
  const [listStock, setListStock] = useState([]);

  const formato = (date) => {
    return date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1');
  }

  const reportApplications = () => {
    setListReport([]);
    setListHarvest([]);
    setListSonws([]);
    setListStock([]);
    setListHeads([]);
    ReportsApi.postReportsApplications(formato(dateStart), formato(dateEnd)).then(resp => {
      console.log('reportAp', resp);
      setListHeads(['Id Aplicación'
        , 'Fecha de Aplicación',
        'Nombre del Empleado',
        'Nombre del Producto',
        'Tipo de Producto',
        'Variedad',
        'Dosis',
        'Finca',
        'Litros de Agua',
        'Número de Camas',
        'Total de Agrochemica',
        'Total de Litros de Agua',]);
      setListReport(resp.result);
      setShowTable(true);
    });
  };

  const reportHarevest = () => {
    setListReport([]);
    setListHarvest([]);
    setListSonws([]);
    setListStock([]);
    setListHeads([]);
    ReportsApi.postReportsHarvest(formato(dateStart), formato(dateEnd)).then(resp => {
      console.log('reportHar', resp);
      setListHeads(['Fecha de Cosecha'
        , 'Id de Cosecha',
        'Precio',
        'Cantidad de Cajas',
        'Cantidad de Cosechas',
        'Cantidad de Kilogramos',
        'Cantidad de Siembra',
        'Variedad',
        'Númer de Semana',]);
      setListHarvest(resp.result);
      setShowTable(true);
    });
  };

  const reportSowns = () => {
    setListReport([]);
    setListHarvest([]);
    setListSonws([]);
    setListStock([]);
    setListHeads([]);
    ReportsApi.postReportsSowns(formato(dateStart), formato(dateEnd)).then(resp => {
      console.log('reportSowns', resp);
      setListHeads(['Id Siembra'
        , 'Id de Cama',
        'Cama Inicial',
        'Cama Final',
        'Area de la siembra',
        'Cantidad de Siembra',
        'Variedad',
        'Semana de Siembra',
        'Nombre del Empleado',
        'Ciclo de Vida',
        'seedTime',]);
      setListSonws(resp.result);
      setShowTable(true);
    });
  };

  const renderCSV = () => {
    switch (labelReport) {
      case 'Reporte Aplicación':
        return (
          <CSVLink data={listReport} filename={'test.csv'}>
            Exportar CSV.
          </CSVLink>
        )
      case 'Reporte Cosecha':
        return (
          <CSVLink data={listHarvest} filename={'test.csv'}>
            Exportar CSV.
          </CSVLink>
        )
      case 'Reporte Siembra':
        return (
          <CSVLink data={listSowns} filename={'test.csv'}>
            Exportar CSV.
          </CSVLink>
        )
      case 'Reporte Stock':
        return (
          <CSVLink data={listStock} filename={'test.csv'}>
            Exportar CSV.
          </CSVLink>
        )
      default:
        return ('');
    }
  };

  const reportStocks = () => {
    setListReport([]);
    setListHarvest([]);
    setListSonws([]);
    setListStock([]);
    setListHeads([]);
    ReportsApi.postReportsStocks(formato(dateStart), formato(dateEnd)).then(resp => {
      console.log('reportStokcs', resp);
      setListHeads(['id Producto',
        'Tipo de Producto',
        'Lote',
        'Cantidad',
        'Fecha de Creación',
        'Fecha de Expiración',
        'Tipo de Aplicación',
        'Empleado',])
      setListStock(resp.result);
      setShowTable(true);
    });
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={8} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Reportes
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={4} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <TextField label={'Fecha Inicial'} value={dateStart}
                           type={'date'} onChange={(e) => {
                  setDateStart(e.target.value);
                }}/>
              </GridCell>
              <GridCell desktop={4} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <Checkbox
                  checked={checked}
                  onChange={(evt) => {
                    setChecked(!!evt.currentTarget.checked);
                    console.log(!!evt.currentTarget.checked)
                  }}
                />
                <TextField label={'Fecha Final'} value={dateEnd}
                           type={'date'} disabled={!checked}
                           onChange={(e) => {
                             setDateEnd(e.target.value);
                           }}/>
              </GridCell>
              <GridCell desktop={4} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                {!open
                  ? <Button label={'Fijar Fecha'} outlined onClick={() => {
                    setOpen(true);
                    setBlocked(false);
                  }}/>
                  : <Button label={'Restaurar Fecha'} outlined onClick={() => {
                    setOpen(false);
                    setBlocked(true);
                    setShowTable(false);
                    setListReport([]);
                  }}/>}
              </GridCell>
            </GridRow>
            <br/>
            <GridRow>
              <GridCell desktop={3} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <Button label={'Reporte Aplicación'} disabled={blocked} outlined
                        onClick={() => {
                          reportApplications();
                          setLabelReport('Reporte Aplicación');
                        }}/>
              </GridCell>
              <GridCell desktop={3} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <Button label={'Reporte Cosecha'} disabled={blocked} outlined
                        onClick={() => {
                          reportHarevest();
                          setLabelReport('Reporte Cosecha');
                        }}/>
              </GridCell>
              <GridCell desktop={3} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <Button label={'Reporte Siembra'} disabled={blocked} outlined
                        onClick={() => {
                          reportSowns();
                          setLabelReport('Reporte Siembra');
                        }}/>
              </GridCell>
              <GridCell desktop={3} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <Button label={'Reporte Stock'} disabled={blocked} outlined
                        onClick={() => {
                          reportStocks();
                          setLabelReport('Reporte Stock');
                        }}/>
              </GridCell>
            </GridRow>
          </Card>
        </GridCell>
        {showTable
          ? <GridCell desktop={12} tablet={12} phone={12}>
            <GridCell desktop={1} tablet={12} phone={12}/>
            <GridCell desktop={9} tablet={12} phone={12}>
              <Card outlined>
                <GridRow>
                  <GridCell desktop={8} tablet={12} phone={12}>
                    <div className={'title-camsal'}>
                      {labelReport}
                      <br/>
                      {renderCSV()}
                    </div>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                            style={{padding: '30px'}}>
                    <DataTable
                      style={{height: '400px', width: '100%'}}>
                      <DataTableContent>
                        <DataTableHead>
                          <DataTableRow>
                            <DataTableHeadCell>#</DataTableHeadCell>
                            {ListHeads.length > 0
                              ? ListHeads.map((row, i) => (
                                <DataTableHeadCell>{ListHeads[i]}</DataTableHeadCell>
                              ))
                              : (<DataTableHeadCell align={'right'}>Cargando Datos</DataTableHeadCell>)}
                          </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                          {listReport.length > 0
                            ?
                            listReport.map((row, inde) => (
                              <DataTableRow>
                                <DataTableCell alignMiddle={true}>{inde + 1}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idApplication}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.dateApplication}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameEmployee}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameProduct}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.type}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.variety}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.dose}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.farm}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.litresWater}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.numberBeds}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.totalAgrochemica}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.totalLitresWater}</DataTableCell>
                              </DataTableRow>
                            ))
                            : ('')}
                          {listHarvest.length > 0
                            ?
                            listHarvest.map((row, ind) => (
                              <DataTableRow>
                                <DataTableCell alignMiddle={true}>{ind + 1}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.harvestDate}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idHarvest}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.price}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.quantityCrate}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.quantityHarvest}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.quantityKg}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.quantitySown}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.variety}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.week}</DataTableCell>
                              </DataTableRow>
                            ))
                            : ('')}
                          {listSowns.length > 0
                            ?
                            listSowns.map((row, ind) => (
                              <DataTableRow>
                                <DataTableCell alignMiddle={true}>{ind + 1}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idSown}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.initialBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.endBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.areaSown}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.countSownCrop}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.variety}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.sownWeek}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameEmployee}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.lifeCycle}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.seedTime}</DataTableCell>
                              </DataTableRow>
                            ))
                            : ('')}
                          {listStock.length > 0
                            ?
                            listStock.map((row, ind) => (
                              <DataTableRow>
                                <DataTableCell alignMiddle={true}>{ind + 1}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idProductNP}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.type}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.lot}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.count}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.creationDate}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.expiration}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.typeProduct}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.registerHolder}</DataTableCell>
                              </DataTableRow>
                            ))
                            : ('')}
                        </DataTableBody>
                      </DataTableContent>
                    </DataTable>
                  </GridCell>
                </GridRow>
                <br/>
              </Card>
            </GridCell>
          </GridCell>
          : ''
        }
      </Grid>
    </>
  );
};

export default Report;
