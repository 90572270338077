import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Redirect} from 'react-router-dom';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import { Elevation } from '@rmwc/elevation';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import FarmApi from '../../../api/FarmApi';

const Farm = () => {
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openRespDell, setOpenRespDell] = useState(false);
  const [redirectLot, setRedirectLot] = useState(false);
  const [edit, setEdit] = useState(true);
  const [IdFarm, setIdFarm] = useState('');
  const [farm, setFarm] = useState('');
  const [updateId, setUpdateId] = useState('');
  const [farms, setFarms] = useState([]);

  useEffect(() => {
    getAllFarms();
  }, []);

  const getAllFarms=()=>{
    FarmApi.getFarms().then((resp) => {
      setFarms(resp.result);
    }).catch((error) => {
      console.log(error);
    });
  };


  const addFarm = (nameF, option) => {
    if (farm.length > 0) {
      if(option){
        FarmApi.updateFarm()
      }
      FarmApi.postData(nameF).then(() => {
        setIdFarm('');
        setFarms(['']);
        getAllFarms();
      });
    }
  };

  const editFarm=(idF)=>{
    FarmApi.getFarm(idF).then((resp)=>{
      const { idFarm,nameFarm } = resp.result[0];
      setFarm(nameFarm);
      setUpdateId(idFarm);
    });
  };

  const sendValuesEdit=(farmEdit)=>{
      if(farmEdit.length >0 ){
        FarmApi.updateFarm(updateId, farmEdit).then(()=>{
          setUpdateId('');
          setEdit(false);
          setFarms(['']);
          getAllFarms();
        });
      }
  };

  const deleteFarm =(idFarm)=>{
    FarmApi.deleteFarm(idFarm).then((resp)=>{
      if(resp.success){
        setFarms(['']);
        getAllFarms();
      }else{
        setOpenRespDell(true);
      }
    });
  };

  if (redirectLot) {
    return <Redirect to={{
      pathname: '/lot',
      state: IdFarm,
    }}/>;
  }

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={2} tablet={12} phone={12}/>
        <GridCell desktop={8} tablet={12} phone={12}>
          <Card outlined>
            <Elevation z={9} key={1}>
              <GridRow>
                <GridCell desktop={8} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <h1>CAMSAL</h1>
                  </div>
                  <div className={'title-camsal'}>
                    Gestión de Fincas
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={4} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Button raised label={'Crear Finca'} onClick={() => {
                      setOpen(true);
                      setEdit(false);
                      setFarm('');
                    }}/>
                  </div>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                          style={{padding: '30px'}}>
                  <DataTable
                    style={{ height: '400px', width: '100%' }}>
                    <DataTableContent>
                      <DataTableHead>
                        <DataTableRow>
                          <DataTableHeadCell>#</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle={true}>ID de la Finca</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle={true}>Nombre de la Finca</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle={true}>Opciones</DataTableHeadCell>
                        </DataTableRow>
                      </DataTableHead>
                      <DataTableBody>
                        {
                          farms.length > 0
                            ? farms.map((row, i) => (
                              <DataTableRow key={i}>
                                <DataTableCell alignMiddle={true}><b>{i + 1}</b></DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idFarm}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameFarm}</DataTableCell>
                                <DataTableCell alignMiddle={true}>
                                  <Button dense  outlined onClick={() => {
                                    setRedirectLot(true);
                                    setIdFarm(row.idFarm);
                                  }}>Ver lotes</Button>
                                  <Button dense  outlined onClick={() => {
                                    setOpen(true);
                                    setEdit(true);
                                    editFarm(row.idFarm);
                                  }}>Modificar</Button>
                                  <Button danger dense outlined onClick={()=>{
                                    setOpenConfirmation(true);
                                    setIdFarm(row.idFarm);
                                  }}>Eliminar</Button>
                                </DataTableCell>
                              </DataTableRow>
                            )) : (
                              <DataTableRow>
                                <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                              </DataTableRow>
                            )
                        }
                      </DataTableBody>
                    </DataTableContent>
                  </DataTable>
                </GridCell>
              </GridRow>
            </Elevation>
          </Card>
        </GridCell>
        <GridCell desktop={2} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Finca' : 'Crear Finca'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Nombre de la Finca'}
                  value={farm} onChange={(e) => setFarm(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Volver</DialogButton>
          {edit
          ?
          <DialogButton onClick={() => {
            sendValuesEdit(farm);
          }} action={'accept'}>
            Editar
          </DialogButton>
        :<DialogButton onClick={() => {
          addFarm(farm);
        }} action={'accept'}>
          Guardar
        </DialogButton>}
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title= "Eliminar Finca"
        body="Esta seguro que quiere eliminar la Finca"
        open={openConfirmation}
        onClose={evt => {
          if(evt.detail.action === 'accept'){
            deleteFarm(IdFarm);
          }
          setOpenConfirmation(false);
        }}
      />
      <SimpleDialog
        title= "Error"
        body="Esta Finca tienes datos internos, No se puede eliminar"
        open={openRespDell}
        onClose={evt => {
          if(evt.detail.action === 'accept'){
            setOpenRespDell(false);
          }
          setOpenRespDell(false);
        }}
      />
    </>
  );
};

export default Farm;
