import {api, escalateError, getResponseData} from './index';

export default class SuppliersApi {

  static getSuppliers() {
    return api.get('/providers')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getSupplier(idSupp) {
    return api.get(`/providers/${idSupp}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static postSuppliers(data) {
    return api.post('/providers/create', {
      name: data.namePro,
      contact: data.contact,
      address: data.address,
      email: data.email,
      type: data.type,
      document: data.document,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static putSupplier(idSupp, data) {
    console.table(data);
    console.log(idSupp);
    return api.put(`/providers/update/${idSupp}`,{
      name: data.namePro,
      contact: data.contact,
      address: data.address,
      email: data.email,
      type: data.type,
      document: data.document,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static deleteSupplier(idSupplier){
    return api.delete(`/providers/delete/${idSupplier}`).then(getResponseData).catch(escalateError);
  }


}
