import {api, escalateError, getResponseData} from './index';

export default class InventoryApi {

  static getStock() {
    return api.get('/stocktakings')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getStockId(id) {
    return api.get(`/stocktakings/${id}`)
      .then(getResponseData)
      .catch(escalateError);
  }


  static getProductsNP(type) {
    return api.get(`/${type}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static postStock(data, expiration) {
    return api.post('/stockTakings/create', {
      count: data.count,
      expiration: expiration,
      idProductNP: data.product,
      lot: data.lotPr,
      registerHolder: data.employee,
      type: data.typeProduct,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static deleteStock(idStock) {
    console.log(idStock);
    return api.delete(`/stocktakings/delete/${idStock}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateStock(id, data) {
    return api.put(`/stockTakings/update/${id}`, {
      count: data.count,
      expiration: data.expiration,
      idProductNP: data.product,
      lot: data.lotPr,
      registerHolder: data.employee,
      type: data.typeProduct,
      typeProduct: 'grLts',
    }).then(getResponseData)
      .catch(escalateError);
  }
}
