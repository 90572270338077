import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import ApplicationsApi from "../../../api/ApplicationsApi";
import {Elevation} from "@rmwc/elevation";
import {Select} from "@rmwc/select";
import EmployeeApi from "../../../api/EmployeeApi";

const Aplication = () => {
  const [openA, setOpenA] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [applications, setApplications] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [listFarms, setListFarms] = useState([]);

  const [employee, setEmployee] = useState('');
  const [typeProduct, setTypeProduct] = useState('');
  const [idProduct, setIdProduct] = useState('');
  const [idFarm, setIdFarm] = useState('');
  const [idApplication, setIdApplication] = useState('');
  const [variety, setVariety] = useState('');
  const [numBeds, setNumBeds] = useState('');
  const [litresW, setLitresW] = useState('');
  const [dose, setDose] = useState('');

  useEffect(() => {
    getAllApplications();
    getAllEmployees();
    getAllFarms();
  }, []);

  const getAllApplications = () => {
    ApplicationsApi.getApplications().then((resp) => {
      setApplications(resp.result);
    }).catch((error) => {
      console.log(error);
    });
  };

  const getAllFarms = () => {
    ApplicationsApi.getFarms().then((resp) => {
      resp.result.forEach((employee) => {
        const {idFarm, nameFarm} = employee;
        setListFarms((oldArray) => [...oldArray,
          {value: idFarm, label: nameFarm}]);
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  const getAllEmployees = () => {
    EmployeeApi.getEmployees().then((resp) => {
      resp.result.forEach((employee) => {
        const {idEmployee, name} = employee;
        setListEmployee((oldArray) => [...oldArray,
          {value: idEmployee, label: name}]);
      });
    });
  };

  const setDataFields =()=>{
    setEmployee('');
    setTypeProduct('');
    setIdProduct('');
    setIdFarm('');
    setVariety('');
    setNumBeds('');
    setLitresW('');
    setDose('');
  };

  const addApplication = () => {
    const data = {employee, typeProduct, idProduct, variety, idFarm, numBeds, dose, litresW};
    ApplicationsApi.postApplication(data).then((resp)=>{
      console.log(resp);
      alert(resp.success);
      setApplications([]);
      getAllApplications();
      setDataFields();
    });
  };

  const saveEditApp = (idApp) => {
    const data = {employee, typeProduct, idProduct, variety, idFarm, numBeds, dose, litresW};
    console.log(data);
    ApplicationsApi.updateApplication(idApp, data).then((resp)=>{
      console.log(resp);
      setApplications([]);
      getAllApplications();
      setDataFields();
    });
  };

  const getApplicationId =(idApp)=>{
    console.log(idApp);
    ApplicationsApi.getApplicationId(idApp).then((resp)=>{
      console.log(resp.result[0]);
      const {idEmployee, type, idProductNP, farm, variety, numberBeds, litresWater, dose } = resp.result[0];
      setEmployee(`${idEmployee}`);
      setTypeProduct(`${type}`);
      setIdProduct(`${idProductNP}`);
      setIdFarm(`${farm}`);
      setVariety(variety);
      setNumBeds(numberBeds);
      setLitresW(litresWater);
      setDose(dose);
    });
  };

  const deleteApplication=(idApp)=>{
    ApplicationsApi.deleteApplication(idApp).then(()=>{
      setApplications([]);
      getAllApplications();
    });
  };

  useEffect(() => {
    setListProduct([]);
    if (typeProduct === 'nutritions') {
      ApplicationsApi.getProductsNutritions().then((resp) => {
        resp.result.forEach((elem) => {
          const {idNutrition, nameProduct} = elem
          setListProduct((oldArray) => [...oldArray,
            {value: idNutrition, label: nameProduct}]);
        });
      });
    }
    if (typeProduct === 'protections') {
      ApplicationsApi.getProductsProtections().then((resp) => {
        resp.result.forEach((elem) => {
          const {idProtection, nameProduct} = elem
          setListProduct((oldArray) => [...oldArray,
            {value: idProtection, label: nameProduct}]);
        });
      });
    }
  }, [typeProduct]);

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={2} tablet={12} phone={12}/>
        <GridCell desktop={8} tablet={12} phone={12}>
          <Card outlined>
            <Elevation z={9} key={1}>
              <GridRow>
                <GridCell desktop={8} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <h1>CAMSAL</h1>
                  </div>
                  <div className={'title-camsal'}>
                    Aplicación a Cultivo
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Button raised label={'Crear Aplicación'} onClick={() => {
                      if(variety.length > 0) setDataFields();
                      setOpenA(true);
                      setEdit(false);
                    }}/>
                  </div>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                          style={{padding: '30px'}}>
                  <DataTable
                    style={{height: '340px', width: '100%'}}>
                    <DataTableContent>
                      <DataTableHead>
                        <DataTableRow>
                          <DataTableHeadCell>ID Tabla</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Nombre Empleado</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Nombre del Producto</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Nombre de siembra</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Fecha de la aplicación</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                        </DataTableRow>
                      </DataTableHead>
                      <DataTableBody>
                        {
                          applications.length > 0
                            ? applications.map((row, i) => (
                              <DataTableRow key={i}>
                                <DataTableCell alignMiddle={true}><b>{i + 1}</b></DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameEmployee}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameProduct}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.variety}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.dateApplication}</DataTableCell>
                                <DataTableCell alignMiddle={true}>
                                  <Button outlined onClick={()=>{
                                    setEdit(true);
                                    setOpenA(true);
                                    setIdApplication(row.idApplication);
                                    getApplicationId(row.idApplication);
                                  }}>Modificar</Button>
                                  <Button danger outlined onClick={()=>{
                                    setOpenConfirmation(true);
                                    setIdApplication(row.idApplication);
                                  }}>Eliminar</Button>
                                </DataTableCell>
                              </DataTableRow>
                            )) : (
                              <DataTableRow>
                                <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                              </DataTableRow>
                            )
                        }
                      </DataTableBody>
                    </DataTableContent>
                  </DataTable>
                </GridCell>
              </GridRow>
            </Elevation>
          </Card>
        </GridCell>
      </Grid>
      <Dialog
        open={openA}
        onClose={() => {
          setOpenA(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Aplicación' : 'Crear Aplicación'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre Empleado'}
                  value={employee}
                  options={listEmployee || []}
                  disabled={listEmployee.length < 1}
                  onChange={(e) => setEmployee(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Tipo de Producto'}
                  options={['nutritions', 'protections']}
                  value={typeProduct}
                  disabled={listEmployee.length < 1}
                  onChange={(e) => {
                    setTypeProduct(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre de Producto'}
                  options={listProduct || []}
                  disabled={listProduct.length < 1}
                  value={idProduct}
                  onChange={(e) => setIdProduct(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Variedad de cultivo'}
                  value={variety} onChange={(e) => setVariety(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Número de Camas'}
                  value={numBeds} onChange={(e) => setNumBeds(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Finca'}
                  options={listFarms || []}
                  disabled={listFarms.length < 1}
                  value={idFarm}
                  onChange={(e) => setIdFarm(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Litros de Agua'}
                  value={litresW} onChange={(e) => setLitresW(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Dosis'}
                  value={dose} onChange={(e) => setDose(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              saveEditApp(idApplication);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addApplication();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>
          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title= "Eliminar Aplicación"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if(evt.detail.action === 'accept'){
            deleteApplication(idApplication);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Aplication;
