import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MaterialWrapper from './components/mdc/MaterialWrapper';
import Home from './components/sites/Home';
import NotFound from './components/sites/NotFound';
import Farm from './components/sites/Farm/farm';
import Lot from './components/sites/Lot/lot';
import TableFarm from './components/sites/TablesFarm/tableFarm';
import Bed from './components/sites/Bed/bed';
import Sown from './components/sites/sown/sown';
import Footer from './components/sites/Footer';
import Aplication from './components/sites/aplication/aplication';
import Disease from './components/sites/Disease/disease';
import Products from './components/sites/Products/products';
import Nutrition from './components/sites/Products/nutrition/ins-nutrition';
import Protection from './components/sites/Products/protection/ins-protection';
import ListNutrition from './components/sites/Products/nutrition/list-nutrition';
import ListProtection from './components/sites/Products/protection/list-protection';
import Harvest from './components/sites/Harvest/harvest';
import Suppliers from './components/sites/suppliers/suppliers';
import Invoice from './components/sites/Invoice/invoice';
import Inventory from './components/sites/Inventory/inventory';
import Report from './components/sites/report/report';
import User from './components/sites/users/user';
import Profile from "./components/sites/profile/profile";

const App = () => (
  <BrowserRouter>
    <MaterialWrapper>
      <Switch>
        <Route exact path={'/'} component={Home}/>
        <Route exact path={'/farm'} component={Farm}/>
        <Route exact path={'/lot'} component={Lot}/>
        <Route exact path={'/tableFarm'} component={TableFarm}/>
        <Route exact path={'/bed'} component={Bed}/>
        <Route exact path={'/sown'} component={Sown}/>
        <Route exact path={'/aplication'} component={Aplication}/>
        <Route exact path={'/disease'} component={Disease}/>
        <Route exact path={'/products'} component={Products}/>
        <Route exact path={'/nutrition'} component={Nutrition}/>
        <Route exact path={'/protection'} component={Protection}/>
        <Route exact path={'/list-nutrition'} component={ListNutrition}/>
        <Route exact path={'/list-protection'} component={ListProtection}/>
        <Route exact path={'/harvest'} component={Harvest}/>
        <Route exact path={'/suppliers'} component={Suppliers}/>
        <Route exact path={'/invoice'} component={Invoice}/>
        <Route exact path={'/inventory'} component={Inventory}/>
        <Route exact path={'/report'} component={Report}/>
        <Route exact path={'/user'} component={User}/>
        <Route exact path={'/sown'} component={Footer}/>
        <Route exact path={'/profile'} component={Profile}/>
        <Route><NotFound/></Route>
      </Switch>
    </MaterialWrapper>
  </BrowserRouter>
);

export default App;
