import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import {Link} from "react-router-dom";
import ProductsApi from "../../../api/ProductsApi";
import {Select} from "@rmwc/select";

const Products = () => {
  const [openP, setOpenP] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [idProduc, seIdProduc] = useState('');
  const [products, setProducts] = useState([]);
  const [nameProduct, setNameProduct] = useState('');
  const [type, setType] = useState('');
  const [activeIngredient, setActiveIngredient] = useState('');
  const [purpose, setPurpose] = useState('');
  const [biologicalWhite, setBiologicalWhite] = useState('');
  const [periodLack, setPeriodLack] = useState('');
  const [regICA, setRegICA] = useState('');


  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = () => {
    ProductsApi.getProducts().then((resp) => {
      setProducts(resp.result);
    }).catch((error) => {
      console.log(error);
    });
  };

  const addNewProduct = () => {
    const data = {
      nameProduct,
      type,
      activeIngredient,
      purpose,
      biologicalWhite,
      periodLack,
      regICA,
    };
    ProductsApi.createProduct(data).then(() => {
      setProducts([]);
      getAllProducts();
      setDataFields();
    });
  };

  const getDataTOEdit = (idProd) => {
    ProductsApi.getProduct(idProd).then((resp) => {
      console.log(resp.result[0]);
      const {
        nameProduct, type, activeIngredient, purpose,
        biologicalWhite, periodLack, regIca,
      } = resp.result[0];
      setNameProduct(nameProduct);
      setType(`${type}`);
      setActiveIngredient(activeIngredient);
      setPurpose(purpose);
      setBiologicalWhite(biologicalWhite);
      setPeriodLack(periodLack);
      setRegICA(regIca);
    });
  };

  const setDataFields = () => {
    setNameProduct('');
    setType('');
    setActiveIngredient('');
    setPurpose('');
    setBiologicalWhite('');
    setPeriodLack('');
    setRegICA('');
  };

  const editProduct = (idPrd) => {
    const data = {
      nameProduct,
      type,
      activeIngredient,
      purpose,
      biologicalWhite,
      periodLack,
      regICA,
    };
    ProductsApi.updateProduct(idPrd, data).then(() => {
      setProducts([]);
      getAllProducts();
      setDataFields();
    });
  };

  const deleteProduct=(id)=>{
    ProductsApi.deleteProduct(id).then(()=>{
      setProducts([]);
      getAllProducts();
      setDataFields();
    });
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={9} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Lista de Productos
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Crear un producto'} onClick={() => {
                    setOpenP(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>ID Tabla</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Ingrediente Activo</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Proposito</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Blanco Biologico</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Periodo de Carencia</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Registro ICA</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        products.length > 0
                          ? products.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}>{i + 1}</DataTableCell>
                              <DataTableCell align={'right'}>{row.nameProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.type}</DataTableCell>
                              <DataTableCell align={'right'}>{row.activeIngredient}</DataTableCell>
                              <DataTableCell align={'right'}>{row.purpose}</DataTableCell>
                              <DataTableCell align={'right'}>{row.biologicalWhite}</DataTableCell>
                              <DataTableCell align={'right'}>{row.periodLack}</DataTableCell>
                              <DataTableCell align={'right'}>{row.regIca}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpenP(true);
                                  seIdProduc(row.idProduct);
                                  getDataTOEdit(row.idProduct);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setOpenConfirmation(true);
                                  seIdProduc(row.idProduct);
                                }} >Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={3} tablet={12} phone={12}/>
              <GridCell desktop={4} tablet={12} phone={12}>
                <Link to={'/nutrition'}>
                  <div className={'title-camsal'}>
                    <Button label={'Ins Nutrición'} onClick={() => setOpenP(true)}/>
                  </div>
                </Link>
              </GridCell>
              <GridCell desktop={4} tablet={12} phone={12}>
                <Link to={'/protection'}>
                  <div className={'title-camsal'}>
                    <Button label={'Ins Protección'} onClick={() => setOpenP(true)}/>
                  </div>
                </Link>
              </GridCell>
            </GridRow>
            <br/>
          </Card>
        </GridCell>
      </Grid>

      <Dialog
        open={openP}
        onClose={() => {
          setOpenP(false);
        }}
      >
        <DialogTitle>{edit ? ' Editar Producto' : 'Crear Producto'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Nombre del Producto'}
                  value={nameProduct} onChange={(e) => setNameProduct(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Tipo de Producto'}
                  options={['nutritions', 'protections']}
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Ingrediente Activo'}
                  value={activeIngredient} onChange={(e) => setActiveIngredient(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Proposito'}
                  value={purpose} onChange={(e) => setPurpose(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Blanco Bilógico'}
                  value={biologicalWhite} onChange={(e) => setBiologicalWhite(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  label={'Periodo de Carencia'}
                  value={periodLack} onChange={(e) => setPeriodLack(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Registro ICA'}
                  value={regICA} onChange={(e) => setRegICA(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'} onClick={() => {
          }}>Cancelar</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              editProduct(idProduc);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addNewProduct();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>

          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Producto"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteProduct(idProduc);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Products;
