import {api, escalateError, getResponseData} from './index';

export default class HarvestApi {

  static getHarvest() {
    return api.get('/harvests')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getIdHarvest(idH) {
    return api.get(`/harvests/${idH}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static createHarvest(dataHarvest) {
    console.log(dataHarvest);
    return api.post('/harvests/create', {
      week: dataHarvest.weekNum,
      harvestDate: dataHarvest.harvestDate,
      variety: dataHarvest.variety,
      quantitySown: dataHarvest.sownCant,
      quantityHarvest: dataHarvest.harvestCant,
      quantityCrate: dataHarvest.crateCant,
      quantityKg: dataHarvest.kg,
      responsible: dataHarvest.responsible,
      price: dataHarvest.price,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static updateHarvest(id, dataHarvest) {
    console.log(dataHarvest);
    return api.put(`/harvests/update/${id}`, {
      week: dataHarvest.weekNum,
      harvestDate: dataHarvest.harvestDate,
      variety: dataHarvest.variety,
      quantitySown: dataHarvest.sownCant,
      quantityHarvest: dataHarvest.harvestCant,
      quantityCrate: dataHarvest.crateCant,
      quantityKg: dataHarvest.kg,
      responsible: dataHarvest.responsible,
      price: dataHarvest.price,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static deleteHarvest(idHarvest) {
    return api.delete(`/harvests/delete/${idHarvest}`).then(getResponseData).catch(escalateError);
  }

}
