import {api, escalateError, getResponseData} from './index';

export default class LoginApi {

  static loginValidation(userL, pass) {
    return api.post('/employees/login', {
      user: userL,
      password: pass,

    }).then(getResponseData)
      .catch(escalateError);
  }
}
