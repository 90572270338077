import {api, escalateError, getResponseData} from './index';

export default class EmployeeApi {

  static getEmployees() {
    return api.get('/employees')
      .then(getResponseData)
      .catch(escalateError);
  }

  static createEmployees(data) {
    return api.post('/employees/create/', {
      email: data.email,
      name: data.name,
      password: data.pass,
      permissions: '',
      phone: data.phone,
      role: data.roles,
      status: data.status,
      user: data.userName,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static updateEmployees(id, data) {
    return api.put(`/employees/update/${id}`, {
      email: data.email,
      name: data.name,
      password: data.pass,
      permissions: '',
      phone: data.phone,
      role: data.roles,
      status: data.status,
      user: data.userName,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static deleteOnlyUser(id) {
    return api.delete(`employees/delete/${id}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getOnlyUser(id) {
    return api.get(`employees/${id}`)
      .then(getResponseData)
      .catch(escalateError);
  }
}
