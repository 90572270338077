import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Link, Redirect, useLocation} from 'react-router-dom';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import FarmApi from '../../../api/FarmApi';
import {Elevation} from "@rmwc/elevation";

const Lot = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [respDelete, setRespDelete] = useState(false);
  const [nameFarm, setNameFarm] = useState('');
  const [IdFarm, setIdFarm] = useState('');
  const [IdLot, setIdLot] = useState('');
  const [lot, setLot] = useState('');
  const [lots, setLots] = useState([]);
  const [redirectTable, setRedirectTable] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    if (location.state === undefined) {
      console.log('Error in send information');
    } else {
      FarmApi.getLotsFarm(location.state).then((resp) => {
        setLots(resp.result);
        FarmApi.getFarm(location.state).then((respFarm) => {
          setNameFarm(respFarm.result[0].nameFarm);
          setIdFarm(respFarm.result[0].idFarm);
        }).catch((error) => {
          console.log(error);
        });
      })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [location.state]);

  const getLots = () => {
    FarmApi.getLotsFarm(location.state).then((resp) => {
      setLots(resp.result);
      FarmApi.getFarm(location.state).then((respFarm) => {
        setNameFarm(respFarm.result[0].nameFarm);
        setIdFarm(respFarm.result[0].idFarm);
      }).catch((error) => {
        console.log(error);
      });
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const addLot = (nameL) => {
    if (lot.length > 0) {
      FarmApi.postLot(IdFarm, nameL).then(() => {
        setLot('');
        setLots(['']);
        getLots();
      });
    }
  };

  const editLot = (LotID) => {
    FarmApi.getLot(LotID).then((resp) => {
      const {nameLot} = resp.result[0];
      setLot(nameLot);
    });
  };

  const sendValuesEdit = (lot) => {
    FarmApi.updateLot(IdLot, IdFarm, lot).then(() => {
      setIdLot('');
      setLots([]);
      getLots();
    });
  };

  const deleteLot = (idLot) => {
    FarmApi.deleteLot(idLot).then((resp) => {
      if(resp.success){
        setLots(['']);
        getLots();
      }else{
        setRespDelete(true);
      }
    });
  };

  if (redirectTable) {
    return <Redirect to={{
      pathname: '/tableFarm',
      state: IdLot,
    }}/>;
  }

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={2} tablet={12} phone={12}/>
        <GridCell desktop={8} tablet={12} phone={12}>
          <Card outlined>
            <Elevation z={9} key={1}>
              <GridRow>
                <GridCell desktop={7} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <h1>CAMSAL</h1>
                  </div>
                  <div className={'title-camsal'}>
                    Gestión de Lotes de la Finca {nameFarm}
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Link to={'/farm'}>
                      <Button danger label={'Volver a Fincas'}/>
                    </Link>
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Button raised label={'Crear Lote'} onClick={() => {
                      setOpen(true);
                      setEdit(false);
                      setLot('');
                    }}/>
                  </div>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                          style={{padding: '30px'}}>
                  <DataTable style={{height: '400px', width: '100%'}}>
                    <DataTableContent>
                      <DataTableHead>
                        <DataTableRow>
                          <DataTableHeadCell>#</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id del Lote</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id de la Finca</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Nombre del lote</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                        </DataTableRow>
                      </DataTableHead>
                      <DataTableBody>
                        {
                          lots.length > 0
                            ? lots.map((row, i) => (
                              <DataTableRow key={i}>
                                <DataTableCell alignMiddle={true}><b>{i + 1}</b></DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idLot}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idFarm}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameLot}</DataTableCell>
                                <DataTableCell alignMiddle={true}>
                                  <Button outlined onClick={() => {
                                    setRedirectTable(true);
                                    setIdLot(row.idLot);
                                  }}>
                                    Ver Tablas</Button>
                                  <Button outlined onClick={() => {
                                    setOpen(true);
                                    editLot(row.idLot);
                                    setIdLot(row.idLot);
                                    setEdit(true);
                                  }}>Modificar</Button>
                                  <Button danger outlined onClick={() => {
                                    setOpenConfirmation(true);
                                    setIdLot(row.idLot);
                                  }}>Eliminar</Button>
                                </DataTableCell>
                              </DataTableRow>
                            )) : (
                              <DataTableRow>
                                <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                              </DataTableRow>
                            )
                        }
                      </DataTableBody>
                    </DataTableContent>
                  </DataTable>
                </GridCell>
              </GridRow>
            </Elevation>
          </Card>
        </GridCell>
        <GridCell desktop={2} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Lote' : 'Crear Lote'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Nombre del Lote'}
                  value={lot} onChange={(e) => setLot(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Volver</DialogButton>
          {edit
            ?
            <DialogButton onClick={() => {
              sendValuesEdit(lot);
            }} action={'accept'}>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addLot(lot);
            }} action={'accept'}>
              Guardar
            </DialogButton>}
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title= "Eliminar Lote"
        body="Esta seguro que quiere eliminar El lote"
        open={openConfirmation}
        onClose={evt => {
          if(evt.detail.action === 'accept'){
            deleteLot(IdLot);
          }
          setOpenConfirmation(false);
        }}
      />
      <SimpleDialog
        title= "Error"
        body="Esta Finca tienes datos internos, No se puede eliminar"
        open={respDelete}
        onClose={evt => {
          if(evt.detail.action === 'accept'){
            setRespDelete(false);
          }
          setRespDelete(false);
        }}
      />
    </>
  );
};

export default Lot;
