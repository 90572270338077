import {api, escalateError, getResponseData} from './index';

export default class SownApi {

    //Sowns
    static getSowns() {
        return api.get('/sowns')
            .then(getResponseData)
            .catch(escalateError);
    }

    //Trae los cultivos por nombres
    static getSown(nameSown) {
        return api.get(`/sowns/${nameSown}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    //Trae un cultivo con el id del cultivo
    static getSownId(idSown) {
        return api.get(`/sowns/id/${idSown}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    //Modificar un cultivo necesita el id y los datos del cultivo
    static updateSown(idSown, data) {
        console.log(idSown, data);
        return api.put(`/sowns/update/${idSown}`,{
            idBed: data.selectBed,
            idEmployee: data.selectEmployee,
            initialBed: data.bedStart,
            endBed: data.bedEnd,
            lifeCycle: data.lifeCicle,
            sownWeek: data.sownWeek,
            variety: data.sownType,
            countSownCrop: data.cantHarvest
        })
            .then(getResponseData)
            .catch(escalateError);
    }

    //Eliminar un cultivo
    static deleteSown(idSown) {
        return api.delete(`/sowns/delete/${idSown}`)
            .then(getResponseData)
            .catch(escalateError);
    }

    //Crear un cultivo necesita data del cultivo
    static createSown(dataSown) {
        return api.post('/sowns/create',{
            idBed: dataSown.selectBed,
            idEmployee: dataSown.selectEmployee,
            initialBed: dataSown.bedStart,
            endBed: dataSown.bedEnd,
            lifeCycle: dataSown.lifeCicle,
            sownWeek: dataSown.sownWeek,
            variety: dataSown.sownType,
            countSownCrop: dataSown.cantHarvest
        })
            .then(getResponseData)
            .catch(escalateError);
    }

    //traer loc ultivos de una determinada cama, necesita el id de la cama
    static getSownsBed(idBed) {
        return api.get(`/sowns/bed/${idBed}`)
            .then(getResponseData)
            .catch(escalateError);
    }
}
