import {api, escalateError, getResponseData} from './index';

export default class ProductsApi {

  //Products
  static getProducts() {
    return api.get('/products')
      .then(getResponseData)
      .catch(escalateError);
  }

  //Traer los priductos de tipo protección
  static getProductsProtections() {
    return api.get('/protections')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getProductsProtectionsName() {
    return api.get('/products/protections')
      .then(getResponseData)
      .catch(escalateError);
  }

  //Trae el producto por id
  static getProduct(idProduct) {
    return api.get(`/products/${idProduct}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  //modificar un producto necesita la id y la data del producto
  static updateProduct(idProduct, data) {
    return api.put(`/products/update/${idProduct}`,{
      nameProduct: data.nameProduct,
      type: data.type,
      activeIngredient: data.activeIngredient,
      purpose: data.purpose,
      biologicalWhite: data.biologicalWhite,
      periodLack: data.periodLack,
      regIca: data.regICA,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  //Eliminar un producto, necesita el id del producto
  static deleteProduct(idProduct) {
    return api.delete(`/products/delete/${idProduct}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  //crear un producto, necesita el data del producto
  static createProduct(data) {
    return api.post('/products/create', {
      nameProduct: data.nameProduct,
      type: data.type,
      activeIngredient: data.activeIngredient,
      purpose: data.purpose,
      biologicalWhite: data.biologicalWhite,
      periodLack: data.periodLack,
      regIca: data.regICA,

    })
      .then(getResponseData)
      .catch(escalateError);
  }

  //PROTECTION
  static getProductIdProtection(idPro) {
    return api.get(`/protections/${idPro}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static deleteProductProtection(idProduct) {
    return api.delete(`/protections/delete/${idProduct}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateProductProtection (id, data){
    return api.put(`protections/update/${id}`, {
      activeIngredient: "activeIngredient2",
      biologicalWhite: "biologicalWhite2",
      count: 955,
      regIca: "33333333333",
      periodLack: 7,
      doseHectare: data.doseHectare,
      doseLitre: data.doseLitre,
      doseSown: data.doseDown,
      erradicant: data.erradicant,
      limitMaxResidue: data.limitMaxResidue,
      nameProduct: data.selectNamePrd,
      periodReEntry: data.periodReEntry,
      protective: data.protective,
      purpose: data.purpose,
      type: data.typeP,
      typeProduct: data.typeAplic,
      volumeHectare: data.volHectare,
      volumeLitre: data.volumeLitre,
      volumeSown: data.volumeSown,

    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static createProductProtection(data) {
    return api.post('/protections/create', {
      activeIngredient: "activeIngredient2",
      biologicalWhite: "biologicalWhite2",
      count: 955,
      regIca: "33333333333",
      periodLack: 7,
      doseHectare: data.doseHectare,
      doseLitre: data.doseLitre,
      doseSown: data.doseDown,
      erradicant: data.erradicant,
      limitMaxResidue: data.limitMaxResidue,
      nameProduct: data.selectNamePrd,
      periodReEntry: data.periodReEntry,
      protective: data.protective,
      purpose: data.purpose,
      type: data.typeP,
      typeProduct: data.typeAplic,
      volumeHectare: data.volHectare,
      volumeLitre: data.volumeLitre,
      volumeSown: data.volumeSown,

    })
      .then(getResponseData)
      .catch(escalateError);
  }


  //NUTRITIONS
  //Traer los priductos de tipo nutrición
  static getProductsNutritions() {
    return api.get('/nutritions')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getProductsNutritionName() {
    return api.get('/products/nutritions')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getProductIdNutritions(idNutri) {
    return api.get(`/nutritions/${idNutri}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static createProductNutrition(data) {
    return api.post('/nutritions/create', {
      nameProduct: data.selectNamePrd,
      type: data.typeProd,
      composition: data.composition,
      purpose: data.purpose,
      volumeHectare: data.volHectare,
      doseHectare: data.doseHectare,
      volumeSown: data.volumeSown,
      doseSown: data.doseDown,
      volumeLitre: data.volumeLitre,
      doseLitre: data.doseLitre,
      categoryToxicology: data.categoryToxicology,
      typeProduct: data.typeAplic,
      count: data.cant,

    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateProductNutrition(id, data) {
    return api.put(`nutritions/update/${id}`, {
      nameProduct: data.selectNamePrd,
      type: data.typeProd,
      composition: data.composition,
      purpose: data.purpose,
      volumeHectare: data.volHectare,
      doseHectare: data.doseHectare,
      volumeSown: data.volumeSown,
      doseSown: data.doseDown,
      volumeLitre: data.volumeLitre,
      doseLitre: data.doseLitre,
      categoryToxicology: data.categoryToxicology,
      typeProduct: data.typeAplic,
      count: data.cant,

    })
      .then(getResponseData)
      .catch(escalateError);
  }


  static deleteProductNutrition(idProduct) {
    return api.delete(`/nutritions/delete/${idProduct}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getTypeProductNutrition(Product) {
    return api.get(`/protections/type/${Product}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getTypeProductProtection(typeProd) {
    return api.get(`/nutritions/type/${typeProd}`)
      .then(getResponseData)
      .catch(escalateError);
  }
}
