import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Select} from '@rmwc/select';
import ProductsApi from "../../../../api/ProductsApi";

const ListProtection = () => {
  const [listProtection, setListProtection] = useState([]);
  const [selectType, setSelectType] = useState('');

  useEffect(() => {
    ProductsApi.getProductsNutritions().then((resp) => {
      setListProtection(resp.result);
    });
  }, []);

  useEffect(() => {
    ProductsApi.getTypeProductProtection(selectType).then((res)=>{
      setListProtection([]);
      setListProtection(res.result);
    });

  }, [selectType]);

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Lista de Insumos de Nutrición
                </div>
              </GridCell>
              <GridCell desktop={4} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Select
                    label={'Seleccione una opción'}
                    enhanced
                    options={['Apl. a Suelo', 'Apl. Foliar']}
                    onChange={(e) => {
                      setSelectType(e.target.value);
                    }}
                  />
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>#</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Id</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Ingrediente Activo</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Proposito</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Blanco Biologico</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Periodo de Entrada</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Periodo de Salida</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis por Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen por litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis por litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Limite de residuo</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listProtection.length > 0
                          ? listProtection.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}>{i + 1}</DataTableCell>
                              <DataTableCell align={'right'}>{row.idProtection}</DataTableCell>
                              <DataTableCell align={'right'}>{row.nameProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.type}</DataTableCell>
                              <DataTableCell align={'right'}>{row.activeIngredient}</DataTableCell>
                              <DataTableCell align={'right'}>{row.purpose}</DataTableCell>
                              <DataTableCell align={'right'}>{row.biologicalWhite}</DataTableCell>
                              <DataTableCell align={'right'}>{row.periodReEntry}</DataTableCell>
                              <DataTableCell align={'right'}>{row.periodLack}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.limitMaxResidue}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined>Modificar</Button>
                                <Button danger outlined>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No Carga</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
          </Card>
        </GridCell>
      </Grid>
    </>
  );
};

export default ListProtection;

