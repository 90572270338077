import {api, escalateError, getResponseData} from './index';

export default class DashboardApi {

  static getCountItemsFarm(id) {
    return api.get(`/farms/count/${id}`)
      .then(getResponseData)
      .catch(escalateError);
  }

}
