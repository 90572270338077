import React, {useState} from 'react';
import {Button} from "@rmwc/button";
import {TextField} from "@rmwc/textfield";
import {Card} from '@rmwc/card';
import {GridCell, GridRow} from "@rmwc/grid";
import ReactDOM from "react-dom";
import './login.css';
import App from "./App";
import Controler from "./res/images/controler.jpg";
import ControlPanel from "./res/images/controlerPanel.png";
import LoginApi from "./api/LoginApi";

const Login = () => {
  const [passWord, setPassWord] = useState('');
  const [user, setUser] = useState('');
  const [validation, setValidation] = useState(false);

  const validationUser = () => {
    if(user.length !== 0 && passWord.length !==0){
      LoginApi.loginValidation(user, passWord).then(resp => {
        const {idEmployee, role, token} = resp.user;
        if (resp.success && token.length > 0) {
          localStorage.setItem('tokenUser', token);
          localStorage.setItem('idEmple', idEmployee);
          localStorage.setItem('rol', role);
          setValidation(true);
        } else {
          alert(JSON.stringify(resp.message));
          setValidation(false);
        }
      });
    }else {
      alert('Ingrese los datos para hacer el login');
    }
  };

  if (validation) {
    ReactDOM.render(<App/>, document.getElementById('root'));
  }

  return (
    <div className={'backImage-login'}>
      <div className={'center-login'}>
        <Card style={{paddingBottom: '1%'}}>
          <GridRow>
            <GridCell align={"middle"} desktop={6} phone={12} tablet={12}>
              <div style={{paddingLeft: '32px'}}>
                <img src={Controler} className={'image-controler'} alt={'Controler Logo'}/>
                <h5 style={{color: "#afafaf"}}>Buenas Practicas Agricolas - BPA</h5>
                <TextField outlined  required
                           label={'Usuario'}
                           type={'text'}
                           value={user} onChange={(e) => setUser(e.target.value)}
                /><br/><br/>
                <TextField outlined required
                           label={'Contraseña'}
                           type={'passWord'}
                           value={passWord} onChange={(e) => setPassWord(e.target.value)}
                /><br/><br/><br/>
                <Button style={{color: '#004681', borderRadius: '20px', width:'70%'}} outlined  label={'Inicio'} onClick={() => {
                  validationUser();
                }}/>
                <p style={{color: "#afafaf"}}>Copyright © Controler 2021</p>
              </div>
            </GridCell>
            <GridCell  desktop={6} phone={12} tablet={12}>
              <img src={ControlPanel} style={{
                width: '25rem',
                paddingTop: '10px',
                paddingRight: '10px',
              }} alt={'Controler Logo'}/>
            </GridCell>
          </GridRow>
        </Card>
      </div>
    </div>
  )
    ;
};
export default Login;
