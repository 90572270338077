import axios from 'axios';

const apiURL = 'https://controler.com.co/camsal-back/api/';

//console.log('in index,', localStorage.getItem('tokenUser'));

const header= {
  'Content-Type': 'application/json',
  Authorization: `camsal-${localStorage.getItem('tokenUser')}`,
};
export const api = axios.create({
  baseURL: apiURL,
  headers: header,
});

export const getApiURL = () => apiURL;
export const getResponseData = (resp) => resp.data;

export const escalateError = (err) => {
  let errorFromResponse;
  try {
    errorFromResponse = err.response.data.error.toString();
  } catch (e) {
    errorFromResponse = undefined;
  }
  const newErr = new Error(errorFromResponse
    || (err instanceof Error
      ? err.message || err.toString()
      : typeof err === 'string'
        ? err
        : err.toString() || 'Error Inesperado'));
  try {
    newErr.data = err.response.data;
  } catch (e) {
  }
  throw newErr;
};

export default api;
