import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Select} from '@rmwc/select';
import ProductsApi from "../../../../api/ProductsApi";

const ListNutrition = () => {
  const [listNutrition, setListNutrition] = useState([]);
  const [typeProduct, setTypeProduct] = useState('');

  useEffect(() => {
    getListNutrition();
  }, []);

  const getListNutrition = () => {
    ProductsApi.getProductsProtections().then((resp)=>{
      setListNutrition(resp.result);
    });
  };

  useEffect(()=>{
    if(typeProduct === 'Plaguicidas'){
      ProductsApi.getTypeProductNutrition(typeProduct).then((resp)=>{
        setListNutrition([]);
        setListNutrition(resp.result);
      });
    }
    if(typeProduct === 'Fungicidas'){
      ProductsApi.getTypeProductNutrition(typeProduct).then((resp)=>{
        setListNutrition([]);
        setListNutrition(resp.result);
      });
    }
    if(typeProduct === 'Insecticidas'){
      ProductsApi.getTypeProductNutrition(typeProduct).then((resp)=>{
        setListNutrition([]);
        setListNutrition(resp.result);
      });
    }
    if(typeProduct === 'Acaricidas'){
      ProductsApi.getTypeProductNutrition(typeProduct).then((resp)=>{
        setListNutrition([]);
        setListNutrition(resp.result);
      });
    }
    if(typeProduct === 'Herbicidas'){
      ProductsApi.getTypeProductNutrition(typeProduct).then((resp)=>{
        setListNutrition([]);
        setListNutrition(resp.result);
      });
    }

  },[typeProduct]);

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={5} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <h1>CAMSAL</h1>
                </div>
                <div className={'title-camsal'}>
                  Lista de Insumos de Protección
                </div>
              </GridCell>
              <GridCell align={'middle'} desktop={6} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Select
                    label={'Seleccione una opción'}
                    enhanced
                    options={['Plaguicidas', 'Fungicidas',
                      'Insecticidas', 'Acaricidas', 'Herbicidas']}
                    onChange={(e) => {
                      setTypeProduct(e.target.value);
                    }}
                  />
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>#</DataTableHeadCell>
                        <DataTableHeadCell>ID</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Composición</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Proposito</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Categoria de Toxicologia</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo de Producto Medición</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listNutrition.length > 0
                          ? listNutrition.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}><b>{i + 1}</b></DataTableCell>
                              <DataTableCell align={'right'}>{row.idNutrition}</DataTableCell>
                              <DataTableCell align={'right'}>{row.nameProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.type}</DataTableCell>
                              <DataTableCell align={'right'}>{row.composition}</DataTableCell>
                              <DataTableCell align={'right'}>{row.purpose}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.categoryToxicology}</DataTableCell>
                              <DataTableCell align={'right'}>{row.typeProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.count}</DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No hay datos por mostrar</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
          </Card>
        </GridCell>
      </Grid>
    </>
  );
};

export default ListNutrition;
