import {api, escalateError, getResponseData} from './index';

export default class ReportsApi {

  static getReports() {
    return api.get('/reportsApplications')
      .then(getResponseData)
      .catch(escalateError);
  }

  static postReportsApplications(dateStart, dateEnd) {
    console.log(dateStart, dateEnd);
    if (dateEnd.length > 0) {
      return api.post('/reportsApplications', {
        dateInitial: dateStart,
        dateEnd: dateEnd || '',
      }).then(getResponseData)
        .catch(escalateError);
    }else{
      return api.post('/reportsApplications', {
        dateInitial: dateStart,
      }).then(getResponseData)
        .catch(escalateError);
    }

  }

  static postReportsHarvest(dateStart, dateEnd) {
    console.log(dateStart, dateEnd);
    if (dateEnd.length > 0) {
      return api.post('/reportsHarvests', {
        dateInitial: dateStart,
        dateEnd: dateEnd || '',
      }).then(getResponseData)
        .catch(escalateError);
    }else{
      return api.post('/reportsHarvests', {
        dateInitial: dateStart,
      }).then(getResponseData)
        .catch(escalateError);
    }

  }

  static postReportsSowns(dateStart, dateEnd) {
    console.log(dateStart, dateEnd);
    if (dateEnd.length > 0) {
      return api.post('/reportsSowns', {
        dateInitial: dateStart,
        dateEnd: dateEnd || '',
      }).then(getResponseData)
        .catch(escalateError);
    }else{
      return api.post('/reportsSowns', {
        dateInitial: dateStart,
      }).then(getResponseData)
        .catch(escalateError);
    }

  }
  static postReportsStocks(dateStart, dateEnd) {
    console.log(dateStart, dateEnd);
    if (dateEnd.length > 0) {
      return api.post('/reportsStock', {
        dateInitial: dateStart,
        dateEnd: dateEnd || '',
      }).then(getResponseData)
        .catch(escalateError);
    }else{
      return api.post('/reportsStock', {
        dateInitial: dateStart,
      }).then(getResponseData)
        .catch(escalateError);
    }

  }

}
