import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Link, useLocation} from 'react-router-dom';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import {Select} from '@rmwc/select';
import FarmApi from "../../../api/FarmApi";
import {Elevation} from "@rmwc/elevation";

const Bed = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [idBed, setIdBed] = useState('');
  const [nameTable, setNameTable] = useState('');
  const [listBeds, setListBeds] = useState([]);
  const [nameBed, setNameBed] = useState('');
  const [typeBed, setTypeBed] = useState('');
  const [countBed, setCountBed] = useState('');

  useEffect(() => {
    if (location.state === undefined) {
      console.log('Error');
    } else {
      setNameTable(location.state);
      getBeds(location.state);
    }
  }, [location.state]);

  const getBeds = (idTable) => {
    FarmApi.getBedTable(idTable).then((rsp) => {
      setListBeds(rsp.result);
    });
  };

  const addBed = () => {
    FarmApi.postBed(location.state, nameBed, parseInt(countBed), typeBed).then((resp) => {
      console.log(resp)
      setTypeBed('');
      setCountBed('');
      setNameBed('');
      setListBeds([]);
      getBeds(location.state);
    });
  };

  const editBed = (idBed) => {
    FarmApi.getBed(idBed).then((resp) => {
      const {nameBed, type, count} = resp.result[0];
      console.log(nameBed, type, count);
      setTypeBed(`${type}`);
      setCountBed(count);
      setNameBed(nameBed);
    });
  };

  const sendValuesEdit = () => {
    FarmApi.updateBed(location.state, idBed, nameBed, countBed, typeBed).then((rsp) => {
      console.log(rsp);
      if (!rsp.success) alert(`No se puede Editar,${rsp.message}`);
      setIdBed('');
      setListBeds([]);
      getBeds(location.state);
    });
  };

  const deleteBed = () => {
    FarmApi.deleteBed(idBed).then((resp) => {
      if (!resp.success) alert(`No se puede eliminar,${resp.message}`);
      setListBeds([]);
      getBeds(location.state);
    });
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <Elevation z={9} key={1}>
              <GridRow>
                <GridCell desktop={7} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <h1>CAMSAL</h1>
                  </div>
                  <div className={'title-camsal'}>
                    Gestión de Camas de la tabla {nameTable}
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Link to={'/farm'}>
                      <Button danger label={'Volver a Finca'}/>
                    </Link>
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Button raised label={'Crear Cama'} onClick={() => {
                      setOpen(true);
                      setTypeBed('');
                      setCountBed('');
                      setNameBed('');
                      setEdit(false);
                    }}/>
                  </div>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                          style={{padding: '30px'}}>
                  <DataTable
                    style={{height: '400px', width: '100%'}}>
                    <DataTableContent>
                      <DataTableHead>
                        <DataTableRow>
                          <DataTableHeadCell>#</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id de Cama</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id de Tabla</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Nombre de la Cama</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Tipo de Cama</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Area de la Cama</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Espacio de la Cama</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                        </DataTableRow>
                      </DataTableHead>
                      <DataTableBody>
                        {
                          listBeds.length > 0
                            ? listBeds.map((row, i) => (
                              <DataTableRow key={i}>
                                <DataTableCell alignMiddle={true}><b>{i + 1}</b></DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idTable}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.type}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.areaBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.count}</DataTableCell>
                                <DataTableCell alignMiddle={true}>
                                  <Button outlined onClick={() => {
                                    setOpen(true);
                                    setIdBed(row.idBed);
                                    setEdit(true);
                                    editBed(row.idBed);
                                  }}>Modificar</Button>
                                  <Button danger outlined onClick={() => {
                                    setIdBed(row.idBed);
                                    setOpenConfirmation(true);
                                  }}>Eliminar</Button>
                                </DataTableCell>
                              </DataTableRow>
                            )) : (
                              <DataTableRow>
                                <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                              </DataTableRow>
                            )
                        }
                      </DataTableBody>
                    </DataTableContent>
                  </DataTable>
                </GridCell>
              </GridRow>
              <br/>
            </Elevation>
          </Card>
        </GridCell>
        <GridCell desktop={1} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Cama' : 'Crear Cama'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Nombre de la Cama'}
                  value={nameBed} onChange={(e) => setNameBed(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={12} tablet={12} phone={12}>
                <Select
                  label={'Tipo de Cama'}
                  enhanced
                  value={typeBed}
                  options={['cama', 'hidroponico']}
                  onChange={(e) => setTypeBed(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={12} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Espacios para siembra de Cama'}
                  value={countBed} onChange={(e) => setCountBed(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          {edit
            ?
            <DialogButton onClick={() => {
              sendValuesEdit(nameTable);
            }} action={'accept'}>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addBed();
            }} action={'accept'}>
              Guardar
            </DialogButton>}
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Cama"
        body="Esta seguro que quiere eliminar esta Cama"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteBed();
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Bed;
