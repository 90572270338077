import React, {useContext} from 'react';
import {Drawer, DrawerContent, DrawerHeader} from '@rmwc/drawer';
import {List, ListItem, ListItemGraphic, ListItemText} from '@rmwc/list';
import DrawerContext from './DrawerContext';
import MDIcon from '../MDIcon';
import Divider from '../Divider';
import {Link} from 'react-router-dom';
import Controler from '../../../res/images/controler.jpg';

const DrawerWrapper = () => {
  const {drawerOpen, setDrawerOpen} = useContext(DrawerContext);
  const adminPermissions = ['farm', 'sown', 'aplication', 'disease', 'products', 'list-nutrition', 'list-protection', 'harvest', 'suppliers', 'invoice', 'inventory', 'report', 'user'];
  const almacenista = ['sown', 'aplication', 'products', 'list-nutrition', 'list-protection', 'harvest', 'suppliers', 'invoice', 'inventory'];
  const fumigador = ['farm', 'sown', 'aplication', 'disease', 'harvest', 'invoice', 'report'];


  const styleIMG = {
    width: '50%',
    paddingTop: '10px',
  };

  const searchRoute = (route) => {
    const rol = localStorage.getItem('rol');
    switch (rol) {
      case 'administrador':
        return adminPermissions.includes(route);
      case 'almacenista':
        return almacenista.includes(route);
      case 'fumigador':
        return fumigador.includes(route);
      case 'coordinador':
        return adminPermissions.includes(route);
      default:
        console.log('default');
        return false;
    }
  };
  return (
    <Drawer modal open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <DrawerHeader style={{paddingLeft: '.4rem'}}>
        <img src={Controler} style={styleIMG} alt={'Controler Logo'}/>
      </DrawerHeader>
      <Divider/>
      <DrawerContent>
        <List>
          <Link to={'/'}>
            <ListItem>
              <ListItemGraphic>
                <MDIcon icon={'home-outline'}/>
              </ListItemGraphic>
              <ListItemText>Home - Dashboard</ListItemText>
            </ListItem>
          </Link>
          {searchRoute('farm')
            ? <Link to={'/farm'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'flower-poppy'}/>
                </ListItemGraphic>
                <ListItemText>Distribución Finca</ListItemText>
              </ListItem>
            </Link>
            : ''
          }
          {searchRoute('sown')
            ? <Link to={'/sown'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'weather-partly-cloudy'}/>
                </ListItemGraphic>
                <ListItemText>Gestión Siembras</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('aplication')
            ? <Link to={'/aplication'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'flask-round-bottom'}/>
                </ListItemGraphic>
                <ListItemText>Aplicación a Cultivo</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('disease')
            ? <Link to={'/disease'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'microscope'}/>
                </ListItemGraphic>
                <ListItemText>Enfermedades</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('products')
            ? <Link to={'/products'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'clipboard-file-outline'}/>
                </ListItemGraphic>
                <ListItemText>Productos</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('list-nutrition')
            ? <Link to={'/list-nutrition'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'shield-check-outline'}/>
                </ListItemGraphic>
                <ListItemText>Insumos de Nutrición</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('list-protection')
            ? <Link to={'/list-protection'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'heart-outline'}/>
                </ListItemGraphic>
                <ListItemText>Insumos de Protección</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('harvest')
            ? <Link to={'/harvest'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'pumpkin'}/>
                </ListItemGraphic>
                <ListItemText>Cosecha</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('suppliers')
            ? <Link to={'/suppliers'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'account-group'}/>
                </ListItemGraphic>
                <ListItemText>Proveedores</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('invoice')
            ? <Link to={'/invoice'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'message-text-outline'}/>
                </ListItemGraphic>
                <ListItemText>Recibo de Caja</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('inventory')
            ? <Link to={'/inventory'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'clipboard'}/>
                </ListItemGraphic>
                <ListItemText>Inventario</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('report')
            ? <Link to={'/report'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'clipboard-check'}/>
                </ListItemGraphic>
                <ListItemText>Reportes</ListItemText>
              </ListItem>
            </Link>
            : ''}
          {searchRoute('user')
            ? <Link to={'/user'}>
              <ListItem>
                <ListItemGraphic>
                  <MDIcon icon={'account-edit'}/>
                </ListItemGraphic>
                <ListItemText>Gestión de Usuarios</ListItemText>
              </ListItem>
            </Link>
            : ''}
        </List>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerWrapper;
