import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import EmployeeApi from "../../../api/EmployeeApi";
import {Select} from "@rmwc/select";

const User = () => {
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [edit, setEdit] = useState(false);
  const [userList, setUserList] = useState([]);
  const [name, setName] = useState('');
  const [idUser, setIDUser] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [roles, setRoles] = useState('');
  const [status, setStatus] = useState('');
  const [pass, setPass] = useState('');

  useEffect(() => {
    getAllUser();
  }, []);

  const getAllUser = () => {
    EmployeeApi.getEmployees().then((resp) => {
      console.log(resp.result);
      setUserList(resp.result);
    });
  };

  const addNewUser = () => {
    console.log('roles', roles);
    const expRCorreo = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (expRCorreo.test(email)) {
      const data = {
        name,
        email,
        userName,
        phone,
        roles,
        status,
        pass,
      };
      console.log(data);
      EmployeeApi.createEmployees(data).then((resp) => {
        console.log(resp);
        setUserList([]);
        getAllUser();
        setDataFields();
      });
    } else {
      alert('Formato de Correo No valido');
      setOpen(false);
    }
  };

  const getDataTOEdit = (id) => {
    EmployeeApi.getOnlyUser(id).then((resp) => {
      console.log(resp.result[0]);
      const {email, name, password, phone, role, status, user} = resp.result[0];
      setName(name);
      setUserName(user);
      setEmail(email);
      setPhone(phone);
      setRoles(`${role}`);
      setStatus(status);
      setPass(password);
    });
  };

  const setDataFields = () => {
    setName('');
    setUserName('');
    setEmail('');
    setPhone('');
    setRoles('');
    setStatus('');
    setPass('');
  };

  const editUser = (id) => {
    const data = {
      name,
      email,
      userName,
      phone,
      roles,
      status,
      pass,
    };
    EmployeeApi.updateEmployees(id, data).then((resp) => {
      console.log(resp);
      setUserList([]);
      getAllUser();
      setDataFields();
    });
  };

  const deleteUser = (id) => {
    EmployeeApi.deleteOnlyUser(id).then(() => {
      setUserList([]);
      getAllUser();
      setDataFields();
    });
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={8} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Lista de Usuarios
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Crear Usuario'} onClick={() => {
                    setOpen(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>ID</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>ID Empleado</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre del Empleado</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Usuario</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Rol</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Email</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Celular</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Estado</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        userList.length > 0
                          ? userList.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}>{i + 1}</DataTableCell>
                              <DataTableCell align={'right'}>{row.idEmployee}</DataTableCell>
                              <DataTableCell align={'right'}>{row.name}</DataTableCell>
                              <DataTableCell align={'right'}>{row.user}</DataTableCell>
                              <DataTableCell align={'right'}>{row.role}</DataTableCell>
                              <DataTableCell align={'right'}>{row.email}</DataTableCell>
                              <DataTableCell align={'right'}>{row.phone}</DataTableCell>
                              <DataTableCell align={'right'}>{row.status}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setOpen(true);
                                  setEdit(true);
                                  setIDUser(row.idEmployee);
                                  getDataTOEdit(row.idEmployee);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setIDUser(row.idEmployee);
                                  setOpenConfirmation(true);
                                }}>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
            <br/>
          </Card>
        </GridCell>
        <GridCell desktop={1} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Añadir al Inventario</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Nombre'}
                  value={name} onChange={(e) => setName(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Nombre de Usuario'}
                  value={userName} onChange={(e) => setUserName(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'email'}
                  label={'Email'}
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Telefono'}
                  value={phone} onChange={(e) => setPhone(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Rol'}
                  options={['administrador', 'coordinador', 'almacenista', 'fumigador']}
                  value={roles}
                  onChange={(e) => {
                    setRoles(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Status'}
                  value={status} onChange={(e) => setStatus(e.target.value)}
                />s
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Contraseña'}
                  value={pass} onChange={(e) => setPass(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'} onClick={() => {
          }}>Cancelar</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              editUser(idUser);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addNewUser();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>

          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Usuario"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteUser(idUser);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default User;
