import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import InvoiceApi from "../../../api/InvoiceApi";
import {Select} from "@rmwc/select";
import SuppliersApi from "../../../api/SuppliersApi";
import Pdf from "react-to-pdf";
import controler from '../../../res/images/controler.jpg'
import './invoice.css';

const Invoice = () => {
  const ref = React.createRef();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [listCashRe, setListCashRe] = useState([]);
  const [listProviders, setListProviders] = useState([]);
  const [idCash, setIdCash] = useState('');
  const [idInvoice, seIdInvoice] = useState('');
  const [selectIdProvider, setSelectIdProvider] = useState('');
  const [description, setDescription] = useState('');
  const [cant, setCant] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [labelProv, setLableProv] = useState('');

  const [namePro, setNamePro] = useState('');
  const [nameEmprePro, setEmprePro] = useState('');
  const [emailPro, setEmailPro] = useState('');
  const [addresPro, setAddresPro] = useState('');
  const [document, setDocumentPro] = useState('');

  useEffect(() => {
    getAllInvoice();
  }, []);

  useEffect(() => {
    console.log(labelProv);
    SuppliersApi.getSupplier(labelProv).then((resp) => {
      const {name, address, email, type, document} = resp.result[0];
      setNamePro(name)
      setEmprePro(type);
      setEmailPro(email);
      setAddresPro(address);
      setDocumentPro(document);
    });

  }, [labelProv]);

  const getAllInvoice = () => {
    InvoiceApi.getInvoices().then((resp) => {
      setListCashRe(resp.result);
      SuppliersApi.getSuppliers().then((resp) => {
        resp.result.forEach((provider) => {
          const {idProvider, name} = provider;
          setListProviders((oldArray) => [...oldArray,
            {value: idProvider, label: name}]);
        });
      });
    });
  };

  const addNewInvoice = () => {
    const data = {
      selectIdProvider,
      description,
      cant,
      unitPrice,
    };
    InvoiceApi.postInvoice(data).then((resp) => {
      console.log(resp);
      setListCashRe([]);
      getAllInvoice();
      setDataFields();
    });
  };

  const setDataFields = () => {
    setSelectIdProvider('');
    setDescription('');
    setCant('');
    setUnitPrice('');
  };

  const renderPDF = (idCash) => {
    const {count, date, description, idCashReceipt, idProvider, totalPrice, unitPrice} = idCash;
    /*InvoiceApi.getOneInvoice(idCash).then((resp)=>{
      data={count, date, description, idCashReceipt, idProvider, totalPrice, unitPrice};
    });*/
    return (
      <div style={{padding: "8%"}}>
        <h2>CAMSAL</h2>
        <h3>Facturación de Pedidos</h3>
        <h4>Pedido para:{nameEmprePro}</h4>
        <h4>Nombres del Contacto:{namePro}</h4>
        <h4>Email:{emailPro}</h4>
        <h4>Dirección:{addresPro}</h4>
        <h4>Documento o Nit:{document}</h4>
        <hr/>
        <br/><br/><br/><br/>
        <div className={'invoice'}>
          <table className="main_inv_table">
            <thead>
            <tr>
              <th className="width0">IdFactura</th>
              <th className="width0">Fecha</th>
              <th className="width0">Descripción</th>
              <th className="width0">Id Proveedor</th>
              <th className="width0">Precio - Unidad</th>
              <th className="width0">Cuenta total</th>
              <th className="width0">Precio - Total</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{idCashReceipt}</td>
              <td>{date}</td>
              <td>{description}</td>
              <td>{idProvider}</td>
              <td>{unitPrice}</td>
              <td>{count}</td>
              <td>{totalPrice}</td>
            </tr>
            <div className="clear"/>
            </tbody>
          </table>
          <br/><br/><br/><br/>
          <img src={controler} style={{
            width: '100px',
            paddingTop: '10px',
          }} alt={'Controler Logo'}/>
          <hr/>
          <h6>Facturación generada por Controler</h6>
        </div>
      </div>);
  };

  const getDataTOEdit = (id) => {
      InvoiceApi.getOneInvoice(id).then((resp) => {
        console.log(resp.result[0]);
        const {unitPrice, count, description, idProvider} = resp.result[0];
        setSelectIdProvider(`${idProvider}`);
        setDescription(description);
        setCant(count);
        setUnitPrice(unitPrice);
      });
    }
  ;

  const editInvoice = (id) => {
      const data = {
        selectIdProvider,
        description,
        cant,
        unitPrice,
      };
      InvoiceApi.updateInvoice(id, data).then((resp) => {
        console.log(resp);
        setListCashRe([]);
        getAllInvoice();
        setDataFields();
      });
    }
  ;

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={8} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Recibos Generados
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Generar Recibo'} onClick={() => {
                    if (description.length > 0) setDataFields();
                    setOpen(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>ID</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>ID Proveedor</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre del Proveedor</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Descripción</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Fecha</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Precio por unidad</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Precio Total</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listCashRe.length > 0
                          ? listCashRe.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}><b>{i + 1}</b></DataTableCell>
                              <DataTableCell align={'right'}>{row.idCashReceipt}</DataTableCell>
                              <DataTableCell align={'right'}>{row.idProvider}</DataTableCell>
                              <DataTableCell align={'right'}>{row.description}</DataTableCell>
                              <DataTableCell align={'right'}>{row.date}</DataTableCell>
                              <DataTableCell align={'right'}>{row.count}</DataTableCell>
                              <DataTableCell align={'right'}>{row.unitPrice}</DataTableCell>
                              <DataTableCell align={'right'}>{row.totalPrice}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpen(true);
                                  seIdInvoice(row.idCashReceipt);
                                  getDataTOEdit(row.idCashReceipt);
                                }}>Editar</Button>
                                <Button outlined onClick={() => {
                                  setOpenPDF(true);
                                  setIdCash(row);
                                  setLableProv(row.idProvider);
                                }}>Generar PDF</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No Carga</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
            <br/>
          </Card>
        </GridCell>
        <GridCell desktop={1} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? ' Editar Recibo' : 'Crear Recibo'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Id del Proveedor'}
                  options={listProviders || []}
                  value={selectIdProvider}
                  disabled={listProviders.length < 1}
                  onChange={(e) => setSelectIdProvider(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Descripción'}
                  value={description} onChange={(e) => setDescription(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField type={'number'}
                           outlined
                           label={'Cantidad'}
                           value={cant} onChange={(e) => setCant(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField type={'number'}
                           outlined
                           label={'Precio por Unidad'}
                           value={unitPrice} onChange={(e) => setUnitPrice(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'} onClick={() => {
          }}>Cancelar</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              editInvoice(idInvoice);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addNewInvoice();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>

          }
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPDF}
        onClose={() => {
          setOpenPDF(false);
        }}
      >
        <DialogTitle>Generar Recibo</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12}>
                <div style={{width: 500, height: 500}} ref={ref}>
                  {openPDF ? renderPDF(idCash) : ''}
                </div>
              </GridCell>
              <br/><br/>
              <GridCell desktop={12} tablet={12} phone={12}>
                <Pdf targetRef={ref} filename="div-blue.pdf">
                  {({toPdf}) => (
                    <button onClick={toPdf}>Generate pdf</button>
                  )}
                </Pdf>
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          <DialogButton onClick={() => addNewInvoice()} action={'accept'} isDefaultAction>
            Guardar
          </DialogButton>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Invoice;
