import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@rmwc/theme';
import {
  TopAppBar, TopAppBarActionItem, TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection,
  TopAppBarTitle
} from '@rmwc/top-app-bar';
import { Button } from '@rmwc/button';
import MDIcon from './MDIcon';
import DrawerContext from './drawer/DrawerContext';
import CamsalLog from './../../res/images/imgDash.png';
import {MenuSurfaceAnchor, Menu, MenuItem} from '@rmwc/menu';
import {Link, Redirect} from 'react-router-dom';

const ToolbarTheme = {
  primary: '#4e73df',
  primaryBg: '#fff',
  secondary: '#6ec901',
  secondaryBg: '#6ec901',
  error: '#d43551',
  background: '#fff',
  onPrimary: '#fff',
  onSecondary: '#0a3066',
};

const Toolbar = ({ useCustomTheme = true }) => {
  const { setDrawerOpen } = useContext(DrawerContext);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState('');

  const renderToolbar = (content) => {
    if (useCustomTheme) {
      return (
        <ThemeProvider options={ToolbarTheme}>
          <TopAppBar style={{ background: '#4e73df' }}>
            {content}
          </TopAppBar>
        </ThemeProvider>
      );
    }
    return (<TopAppBar>{content}</TopAppBar>);
  };

  const Redirection = () => {
    if (page) {
      return <Redirect to={`${page}`} />;
    }
    return <></>;
  };

  const logout =()=>{
    setPage('');
    localStorage.removeItem('tokenUser');
    // window.location.replace('');
    window.location.href = 'https://camsal-bpa.controler.com.co/';
  }

  return (
    <>
      {renderToolbar(
        <TopAppBarRow>
          <Redirection/>
          <TopAppBarSection alignStart>
            <TopAppBarActionItem icon={''} onClick={() => setDrawerOpen(true)}>
              <MDIcon icon={'menu'}/>
            </TopAppBarActionItem>
            <Link to={'/'} className={'toolbar-logo'}>
              <img alt={'Camsal Logo'} src={CamsalLog}/>
            </Link>
            <Link to={'/'}>
              <TopAppBarTitle>CAMSAL</TopAppBarTitle>
            </Link>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <MenuSurfaceAnchor>
              <Menu
                open={open}
                onSelect={evt => console.log(evt.detail.index)}
                onClose={() => setOpen(false)}
              >
                <MenuItem onClick={()=>setPage('/profile')}>Mi Perfil</MenuItem>
                {/** MenuItem is just a ListItem, so you can intermingle other List components */}
                <MenuItem onClick={()=>logout()}>Salir</MenuItem>
              </Menu>

              <Button raised onClick={() => setOpen(!open)}>
                Menu
              </Button>
            </MenuSurfaceAnchor>
          </TopAppBarSection>
        </TopAppBarRow>
      )}
      <TopAppBarFixedAdjust/>
    </>
  );
};

Toolbar.propTypes = {
  useCustomTheme: PropTypes.bool,
};

export default Toolbar;
