import React, {useEffect, useState} from 'react';
import {Card} from '@rmwc/card';
import {Grid, GridRow, GridCell} from "@rmwc/grid";
import AnyChart from 'anychart-react'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Select} from "@rmwc/select";
import FarmApi from "../api/FarmApi";
import DashboardApi from "../api/DashboardApi";
import './dasboard.css'

const MyComponent = () => {
  const [legend, setLegend] = useState(false);
  const [listFarm, setListFarms] = useState([]);
  const [selectFarm, setSelectFarm] = useState('');
  const [beds, setBeds] = useState(1);
  const [lots, setLots] = useState(1);
  const [sows, setSows] = useState(1);
  const [tables, setTables] = useState(1);

  useEffect(() => {
    getAllFarms();
  }, []);

  useEffect(() => {
    if (selectFarm) {
      DashboardApi.getCountItemsFarm(selectFarm).then(resp => {
        console.log(resp.resultCount);
        const {beds, lots, sowns, tables} = resp.resultCount;
        if (beds + lots + sowns + tables !== 0) {
          setBeds(beds);
          setLots(lots);
          setSows(sowns);
          setTables(tables);
        } else {
          alert('La Finca seleccionada aun no tiene datos');
          setBeds(0);
          setLots(0);
          setSows(0);
          setTables(0);
        }
      });
    }
  }, [selectFarm]);

  const getAllFarms = () => {
    console.log('in get', localStorage.getItem('tokenUser'));
    FarmApi.getFarms().then((resp) => {
      resp.result.forEach((elem) => {
        const {idFarm, nameFarm} = elem;
        setListFarms((oldArray) => [...oldArray,
          {label: nameFarm, value: idFarm}]);
      });
    });
  };

  /*
   http://controler.com.co/bpa/api/products
  * */

  const dataTest = [
    ["Lotes", lots],
    ["Tablas", tables],
    ["Camas", beds],
    ["Siembras", sows],
  ];
  return (
    <Grid>
      <GridRow>
        <GridCell desktop={3}/>
        <GridCell desktop={6} phone={12} tablet={6}>
          <Card outlined>
            <div className={'title-camsal'}>
              <h1>CAMSAL</h1>
            </div>
            <GridCell desktop={3} tablet={12} phone={12}>
              <Select
                label={'Nombre de la Finca'}
                options={listFarm || []}
                value={selectFarm}
                disabled={listFarm.length < 1}
                onChange={(e) => {
                  setSelectFarm(e.target.value);
                }}
              />
            </GridCell>
            <Tabs>
              <TabList>
                <Tab>Gráfica Circular</Tab>
                <Tab>Gráfica Barras</Tab>
                <Tab>Gráfica Lineas</Tab><br/>
              </TabList>
              <TabPanel>
                <div>
                  <AnyChart position={'outside'} width={500} height={500} title="Gráfica Circular por Finca" type="pie" data={dataTest}/>
                </div>
              </TabPanel>
              <TabPanel>
                <label>&nbsp;&nbsp;Legend: <input id="legendChange" type="checkbox"
                                                  onChange={() => setLegend(true)}/></label>
                <AnyChart position={'outside'} legend={legend} width={500} height={500} title="Gráfica en Barras por Finca" type="column"
                          data={dataTest}/>
              </TabPanel>
              <TabPanel>
                <div>
                  <AnyChart position={'outside'} width={500} height={500} title="Gráfica Demo Lineas" type="line"
                            data={dataTest}/>
                </div>
              </TabPanel>
            </Tabs>
          </Card>
        </GridCell>
      </GridRow>
    </Grid>
  );
};

export default MyComponent;
