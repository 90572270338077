import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import InventoryApi from "../../../api/InventoryApi";
import {Select} from "@rmwc/select";
import EmployeeApi from "../../../api/EmployeeApi";

const Inventory = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [listStock, setListStock] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const [employee, setEmployee] = useState('');
  const [idStock, setIdStock] = useState('');
  const [typeProduct, setTypeProduct] = useState('');
  const [product, setProduct] = useState('');
  const [expiration, setExpiration] = useState('');
  const [lotPr, setLotPr] = useState('');
  const [count, setCount] = useState('');

  const setDataFields = () => {
    setEmployee('');
    setTypeProduct('');
    setProduct('');
    setExpiration('');
    setLotPr('');
    setCount('');
  };

  useEffect(() => {
    getAllStock();
    getAllEmployees();
  }, []);

  const getAllStock = () => {
    InventoryApi.getStock().then((resp) => {
      setListStock(resp.result);
    });
  }

  const getAllEmployees = () => {
    EmployeeApi.getEmployees().then((resp) => {
      resp.result.forEach((employee) => {
        const {name} = employee;
        setListEmployee((oldArray) => [...oldArray, name]);
      });
    });
  };

  useEffect(() => {
    setListProducts([]);
    if (typeProduct) {
      InventoryApi.getProductsNP(typeProduct).then((resp) => {
        resp.result.forEach((elem) => {
          setListProducts((oldArray) => [...oldArray,
            {value: elem.idNutrition || elem.idProtection, label: elem.nameProduct}]);
        });
      });
    }
  }, [typeProduct]);

  /* const formato = (date) => {
     return date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1');
   }*/

  const addNewInventory = () => {
    const data = {
      typeProduct,
      product,
      lotPr,
      employee,
      count,
    };
    InventoryApi.postStock(data, expiration).then(() => {
      setListStock([]);
      setDataFields();
      getAllStock();
    });
  };

  const deleteInvetary = (idInv) => {
    InventoryApi.deleteStock(idInv).then(() => {
      setListStock([]);
      getAllStock();
    });
  };

  const getDateToEdit = (id) => {
    InventoryApi.getStockId(id).then(resp => {
      const {registerHolder, type, idProductNP, expiration, lot, count} = resp.result[0]
      setEmployee(`${registerHolder}`);
      setTypeProduct(type);
      setProduct(`${idProductNP}`);
      setExpiration(expiration);
      setLotPr(lot);
      setCount(count);
    });
  };

  const saveEditApp =(id)=>{
    const data = {
      typeProduct,
      product,
      lotPr,
      employee,
      count,
      expiration,
    };
    InventoryApi.updateStock(id, data).then((resp) => {
      console.log(resp);
      setListStock([]);
      setDataFields();
      getAllStock();
    });
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={8} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Registro de Inventario
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Añadir Inventario'} onClick={() => {
                    setOpen(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>#</DataTableHeadCell>
                        <DataTableHeadCell>ID Inventario</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>ID Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Expiración</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Lote</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Registro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Fecha de Creación</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listStock.length > 0
                          ? listStock.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}><b>{i + 1}</b></DataTableCell>
                              <DataTableCell align={'right'}>{row.idStockTaking}</DataTableCell>
                              <DataTableCell align={'right'}>{row.idProductNP}</DataTableCell>
                              <DataTableCell align={'right'}>{row.type}</DataTableCell>
                              <DataTableCell align={'right'}>{row.expiration}</DataTableCell>
                              <DataTableCell align={'right'}>{row.lot}</DataTableCell>
                              <DataTableCell align={'right'}>{row.registerHolder}</DataTableCell>
                              <DataTableCell align={'right'}>{row.creationDate}</DataTableCell>
                              <DataTableCell align={'right'}>{row.count}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpen(true);
                                  setIdStock(row.idStockTaking);
                                  getDateToEdit(row.idStockTaking);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setIdStock(row.idStockTaking);
                                  setOpenConfirmation(true);
                                }}>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No Carga</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
            <br/>
          </Card>
        </GridCell>
        <GridCell desktop={1} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Inventario' : 'Añadir al Inventario'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre Empleado'}
                  value={employee}
                  options={listEmployee || []}
                  disabled={listEmployee.length < 1}
                  onChange={(e) => setEmployee(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Tipo de Producto'}
                  options={['nutritions', 'protections']}
                  value={typeProduct}
                  onChange={(e) => {
                    setTypeProduct(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre de Producto'}
                  options={listProducts || []}
                  disabled={listProducts.length < 1}
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Fecha de Expiración'}
                  placeholder={'dd/mm/aaaa'}
                  value={expiration} onChange={(e) => setExpiration(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Lote de Producto'}
                  value={lotPr} onChange={(e) => setLotPr(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cantidad'}
                  value={count} onChange={(e) => setCount(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'} onClick={() => {
          }}>Cancelar</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
                saveEditApp(idStock);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addNewInventory();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>

          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Aplicación"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteInvetary(idStock);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Inventory;
