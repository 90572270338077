import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import SownApi from "../../../api/SownApi";
import EmployeeApi from "../../../api/EmployeeApi";
import {Select} from "@rmwc/select";
import FarmApi from "../../../api/FarmApi";
import {Elevation} from "@rmwc/elevation";

const Sown = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [listSowns, setListSowns] = useState([]);

  const [listFarm, setListFarms] = useState([]);
  const [selectOneFarm, setSelectFarm] = useState('');
  const [listLots, setListLots] = useState([]);
  const [selectOneLot, setSelectLot] = useState('');

  const [listTables, setListTables] = useState([]);
  const [selectOneTable, setSelectTable] = useState('');

  const [listnameBed, setNameListBed] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);

  const [selectEmployee, setSelectEmployee] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [selectBed, setSelectBed] = useState('');
  const [bedStart, setBedStart] = useState('');
  const [bedEnd, setBedEnd] = useState('');
  const [sownWeek, setSownWeek] = useState('');
  const [lifeCicle, setLifeCicle] = useState('');
  const [cantHarvest, setCantHarvest] = useState('');
  const [sownType, setSownType] = useState('');
  const [idSownEdit, setIdSownEdit] = useState('');


  useEffect(() => {
    getAllSowns();
    getAllFarms();
    getAllEmployees();
  }, []);

  const getAllSowns = () => {
    SownApi.getSowns().then((resp) => {
      setListSowns(resp.result);
    }).catch((error) => {
      console.log(error);
    });
  };

  const getAllFarms = () => {
    FarmApi.getFarms().then((resp) => {
      resp.result.forEach((elem) => {
        const {idFarm, nameFarm} = elem;
        setListFarms((oldArray) => [...oldArray,
          {label: nameFarm, value: idFarm}]);
      });
    });
  };

  useEffect(() => {
    setListLots([]);
    setListTables([]);
    setNameListBed([]);
    if (selectOneFarm.length > 0) {
      console.log(selectOneFarm);
      FarmApi.getLotsFarm(selectOneFarm).then((resp) => {
        resp.result.forEach((elem) => {
          const {idLot, nameLot} = elem;
          setListLots((oldArray) => [...oldArray,
            {label: nameLot, value: idLot}]);
        });
      });
    } else {
      setListTables([]);
    }
  }, [selectOneFarm]);

  useEffect(() => {
    setListTables([]);
    setNameListBed([]);
    if (selectOneLot.length > 0) {
      console.log(selectOneLot);
      FarmApi.getTablesLot(selectOneLot).then((resp) => {
        if (resp.result !== 0) {
          resp.result.forEach((elem) => {
            const {idTable, nameTable} = elem;
            setListTables((oldArray) => [...oldArray,
              {label: nameTable, value: idTable}]);
          });
        } else {
          setSelectTable('');
        }
      });
    } else {
      setSelectTable('');
      setNameListBed([]);
    }
  }, [selectOneLot]);

  useEffect(() => {
    setNameListBed([]);
    if (selectOneTable.length > 0) {
      console.log(selectOneTable);
      FarmApi.getBedTable(selectOneTable).then((resp) => {
        resp.result.forEach((bed) => {
          console.log(bed);
          const {idBed, nameBed} = bed;
          setNameListBed((oldArray) => [...oldArray,
            {value: idBed, label: `Id:${idBed} - ${nameBed}`}]);
        });
      });
    }
  }, [selectOneTable]);

  const getAllEmployees = () => {
    EmployeeApi.getEmployees().then((resp) => {
      resp.result.forEach((employee) => {
        const {idEmployee, name} = employee;
        setListEmployee((oldArray) => [...oldArray,
          {value: idEmployee, label: name}]);
      });
    });
  };

  const setValuesSown = () => {
    setListLots([]);
    setListTables([]);
    setNameListBed([]);
    setSelectEmployee('');
    setSelectBed('');
    setBedStart('');
    setBedEnd('');
    setSownWeek('');
    setLifeCicle('');
    setCantHarvest('');
    setSownType('');
  };

  const addNewSown = () => {
    const data = {
      selectEmployee,
      selectBed,
      bedStart,
      bedEnd,
      sownWeek,
      lifeCicle,
      cantHarvest,
      sownType
    };
    SownApi.createSown(data).then((resp) => {
      console.log(resp);
      if (resp.success) {
        alert(resp.sownResult || resp.sownResult);
      } else {
        alert(resp.message);
      }
      setListSowns([]);
      getAllSowns();
      setValuesSown();
    })
  };

  const deleteSown = (idSown) => {
    SownApi.deleteSown(idSown).then(() => {
      setListSowns([]);
      getAllSowns();
    })
  };

  const editSown = (idSown) => {
    setIdSownEdit(idSown);
    SownApi.getSownId(idSown).then((resp) => {
      console.log(resp.result[0]);
      console.log(employeeName);
      const {
        idBed,
        initialBed,
        idEmployee,
        nameEmployee,
        endBed,
        sownWeek,
        lifeCycle,
        countSownCrop,
        variety
      } = resp.result[0];
      setSelectBed(`${idBed}`);
      setBedStart(initialBed);
      setSelectEmployee(`${idEmployee}`);
      setEmployeeName(nameEmployee);
      setBedEnd(endBed);
      setSownWeek(sownWeek);
      setSownType(variety);
      setLifeCicle(lifeCycle);
      setCantHarvest(countSownCrop);
    });
  };

  const saveEditSown = () => {
    console.log(`selectEmployee- ${selectEmployee} -selectBed ${selectBed} - sownType ${sownType}`);
    if (selectEmployee.length > 0 && selectBed.length > 0 && sownType.length > 0) {
      const data = {
        selectEmployee,
        selectBed,
        bedStart,
        bedEnd,
        sownWeek,
        lifeCicle,
        cantHarvest,
        sownType
      };
      SownApi.updateSown(idSownEdit, data).then((resp) => {
        console.log(resp);
        if (!resp.success) alert(resp.message || resp.result);
        setListSowns([]);
        getAllSowns();
        setValuesSown();
      });
    } else {
      alert('Faltan Datos por llenar, no se Modifico el Campo');
      setValuesSown();
    }
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <Elevation z={9} key={1}>
              <GridRow>
                <GridCell desktop={7} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <h1>CAMSAL</h1>
                  </div>
                  <div className={'title-camsal'}>
                    Gestión de Siembras
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Button raised label={'Crear Siembra'} onClick={() => {
                      setOpen(true);
                      setEdit(false);
                      setValuesSown();
                    }}/>
                  </div>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                          style={{padding: '30px'}}>
                  <DataTable
                    style={{height: '400px', width: '100%'}}>
                    <DataTableContent>
                      <DataTableHead>
                        <DataTableRow>
                          <DataTableHeadCell>#</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id Siembra</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Tipo de Cultivo</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id Cama</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Empleado Encargado</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Cama Inicial</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Cama Final</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Tiempo de Siembra</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Semana de Siembra</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Ciclo de Vida</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Area de Siembra (m^2)</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Cantidad de Semillas</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                        </DataTableRow>
                      </DataTableHead>
                      <DataTableBody>
                        {
                          listSowns.length > 0
                            ? listSowns.map((row, i) => (
                              <DataTableRow key={i}>
                                <DataTableCell alignMiddle={true}><strong>{i + 1}</strong></DataTableCell>
                                <DataTableCell alignMiddle={true}><strong>{row.idSown}</strong></DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.variety}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameEmployee}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.initialBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.endBed}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.seedTime}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.sownWeek}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.lifeCycle}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.areaSown}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.countSownCrop}</DataTableCell>
                                <DataTableCell alignMiddle={true}>
                                  <Button outlined onClick={() => {
                                    setOpen(true);
                                    setEdit(true);
                                    editSown(row.idSown);
                                  }}>Modificar</Button>
                                  <Button danger outlined onClick={() => {
                                    setOpenConfirmation(true);
                                    setIdSownEdit(row.idSown);
                                  }}>Eliminar</Button>
                                </DataTableCell>
                              </DataTableRow>
                            )) : (
                              <DataTableRow>
                                <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                              </DataTableRow>
                            )
                        }
                      </DataTableBody>
                    </DataTableContent>
                  </DataTable>
                </GridCell>
              </GridRow>
            </Elevation>
          </Card>
        </GridCell>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? ' Editar Siembra' : 'Crear Siembra'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre de la Finca'}
                  options={listFarm || []}
                  disabled={listFarm.length < 1}
                  onChange={(e) => {
                    setSelectFarm(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre del Lote'}
                  options={listLots || []}
                  value={`${selectOneLot}`}
                  disabled={listLots.length < 1}
                  onChange={(e) => {
                    setSelectLot(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre de la Tabla'}
                  options={listTables || []}
                  disabled={listTables.length < 1}
                  value={`${selectOneTable}`}
                  onChange={(e) => {
                    setSelectTable(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre de la Cama'}
                  options={listnameBed || []}
                  value={selectBed}
                  disabled={listnameBed.length < 1}
                  onChange={(e) => {
                    setSelectBed(e.target.value)
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre del Empleado'}
                  options={listEmployee || []}
                  value={selectEmployee}
                  disabled={listEmployee.length < 1}
                  onChange={(e) => setSelectEmployee(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cama Inicial'}
                  value={bedStart} onChange={(e) => setBedStart(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cama Final'}
                  value={bedEnd} onChange={(e) => setBedEnd(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Número de la Semana'}
                  value={sownWeek} onChange={(e) => setSownWeek(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Ciclo de Vida'}
                  value={lifeCicle} onChange={(e) => setLifeCicle(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cant de Cultivo'}
                  value={cantHarvest} onChange={(e) => setCantHarvest(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Tipo de Siembra'}
                  value={sownType} onChange={(e) => setSownType(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'} onClick={() => {
            setValuesSown();
          }}>Cancelar</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              saveEditSown();
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addNewSown();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>

          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Siembra"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteSown(idSownEdit);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Sown;
