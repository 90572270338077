import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import SuppliersApi from "../../../api/SuppliersApi";

const Suppliers = () => {
  const [idSupp, setIdSupp] = useState('');
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [listSuppliers, setListSuppliers] = useState([]);
  const [namePro, setNamePro] = useState('');
  const [contact, setContact] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [document, setDocument] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    SuppliersApi.getSuppliers().then((resp) => {
      setListSuppliers(resp.result);
    });
  }, []);

  const addNewSupplier = () => {
    const expRCorreo = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (expRCorreo.test(email)) {
      const data = {
        namePro,
        contact,
        address,
        email,
        type,
        document,
      };
      SuppliersApi.postSuppliers(data).then(() => {
        setListSuppliers([]);
        setDataFields();
        SuppliersApi.getSuppliers().then((resp) => {
          setListSuppliers(resp.result);
        });
      });
    } else {
      alert('Formato de Correo No valido');
      setOpen(false);
    }
  };

  const setDataFields=()=>{
    setNamePro('');
    setContact('');
    setAddress('');
    setEmail('');
    setType('');
    setDocument('');
  }

  const deleteSupplier = (idSupplier) => {
    SuppliersApi.deleteSupplier(idSupplier).then(() => {
      setListSuppliers([]);
      SuppliersApi.getSuppliers().then((resp) => {
        setListSuppliers(resp.result);
      });
    });
  };

  const editSupp = (idSup) => {
    SuppliersApi.getSupplier(idSup).then((resp) => {
      const {name, contact, address, email, type, document} = resp.result[0];
      setNamePro(name);
      setContact(contact);
      setAddress(address);
      setEmail(email);
      setType(type);
      setDocument(document);
    });
  };

  const sendValuesEdit = () => {
    const data = {
      namePro,
      contact,
      address,
      email,
      type,
      document,
    };
    SuppliersApi.putSupplier(idSupp, data).then(() => {
      setListSuppliers([]);
      SuppliersApi.getSuppliers().then((resp) => {
        setListSuppliers(resp.result);
      });
    });
  };
  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={7} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <h1>CAMSAL</h1>
                </div>
                <div className={'title-camsal'}>
                  Lista de Proveedores
                </div>
              </GridCell>
              <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Registrar Proveedor'} onClick={() => {
                    if(type.length>0) setDataFields();
                    setOpen(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>ID</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>ID Proveedor</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Número de Contacto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dirección</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Email</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Document</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listSuppliers.length > 0
                          ? listSuppliers.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}><b>{i + 1}</b></DataTableCell>
                              <DataTableCell align={'right'}>{row.idProvider}</DataTableCell>
                              <DataTableCell align={'right'}>{row.name}</DataTableCell>
                              <DataTableCell align={'right'}>{row.contact}</DataTableCell>
                              <DataTableCell align={'right'}>{row.address}</DataTableCell>
                              <DataTableCell align={'right'}>{row.email}</DataTableCell>
                              <DataTableCell align={'right'}>{row.type}</DataTableCell>
                              <DataTableCell align={'right'}>{row.document}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpen(true);
                                  editSupp(row.idProvider);
                                  setIdSupp(row.idProvider);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setOpenConfirmation(true);
                                  setIdSupp(row.idProvider);
                                }}>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No Carga</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
            <br/>
          </Card>
        </GridCell>
        <GridCell desktop={1} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Registrar Proveedor</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Nombre de la empresa'}
                  value={type} onChange={(e) => setType(e.target.value)}/>
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Nombre Proveedor'}
                  value={namePro} onChange={(e) => setNamePro(e.target.value)}/>
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Número de Contacto'}
                  value={contact} onChange={(e) => setContact(e.target.value)}/>
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Dirección'}
                  value={address} onChange={(e) => setAddress(e.target.value)}/>
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Email'}
                  value={email} onChange={(e) => setEmail(e.target.value)}/>
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Documento o NIT'}
                  value={document} onChange={(e) => setDocument(e.target.value)}/>
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          {edit
            ?
            <DialogButton onClick={() => {
              sendValuesEdit();
            }} action={'accept'}>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addNewSupplier();
            }} action={'accept'}>
              Guardar
            </DialogButton>}
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Proveedor"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteSupplier(idSupp);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Suppliers;
