import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Link, Redirect, useLocation} from 'react-router-dom';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import FarmApi from "../../../api/FarmApi";
import {Elevation} from "@rmwc/elevation";

const TableFarm = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [respDell, setRespDell] = useState(false);
  const [nameTable, setNameTable] = useState('');
  const [nameLot, setNameLot] = useState('');
  const [idLot, setIdLot] = useState('');
  const [idTable, setIdTable] = useState('');
  const [redirectBed, setRedirectBed] = useState(false);
  const [allTables, setAllTables] = useState([]);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    if (location.state === undefined) {
      console.log('Error');
    } else {
      FarmApi.getTablesLot(location.state).then((resp) => {
        setAllTables(resp.result);
        FarmApi.getLot(location.state).then((respLot) => {
          setNameLot(respLot.result[0].nameLot);
          setIdLot(respLot.result[0].idLot);
        });
      }).catch((err) => console.log(err));
    }
  }, [location.state]);

  const getOnlyTable = () => {
    FarmApi.getTablesLot(location.state).then((resp) => {
      setAllTables(resp.result);
      FarmApi.getLot(location.state).then((respLot) => {
        setNameLot(respLot.result[0].nameLot);
        setIdLot(respLot.result[0].idLot);
      });
    }).catch((err) => console.log(err));
  };

  const addTable = () => {
    FarmApi.postTable(idLot, nameTable).then(() => {
      setNameTable('');
      setAllTables([]);
      getOnlyTable();
    });
  };

  const editTable = (idT) => {
    FarmApi.getTable(idT).then((resp) => {
      const {nameTable} = resp.result[0];
      setNameTable(nameTable);
    });
  };

  const sendValuesEdit = () => {
    FarmApi.updateTable(idTable, idLot, nameTable).then(() => {
      setIdTable('');
      setAllTables([]);
      getOnlyTable();
    });
  };

  const deleteTable = (idTa) => {
    FarmApi.deleteTable(idTa).then((resp)=>{
      if(resp.success){
        setAllTables([]);
        getOnlyTable();
      }else{
       setRespDell(true);
      }
    });
  };

  if (redirectBed) {
    return <Redirect to={{
      pathname: '/bed',
      state: idTable,
    }}/>;
  }

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={2} tablet={12} phone={12}/>
        <GridCell desktop={8} tablet={12} phone={12}>
          <Card outlined>
            <Elevation z={9} key={1}>
              <GridRow>
                <GridCell desktop={7} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <h1>CAMSAL</h1>
                  </div>
                  <div className={'title-camsal'}>
                    Gestión de Tablas del Lote - {nameLot}
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Link to={'/farm'}>
                      <Button danger label={'Volver a Finca'}/>
                    </Link>
                  </div>
                </GridCell>
                <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                  <div className={'title-camsal'}>
                    <Button raised label={'Crear Tabla'} onClick={() => {
                      setOpen(true);
                      setEdit(false);
                      setNameTable('');
                    }}/>
                  </div>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                          style={{height: '380px', padding: '30px'}}>
                  <DataTable style={{height: '300px', width: '100%'}}>
                    <DataTableContent>
                      <DataTableHead>
                        <DataTableRow>
                          <DataTableHeadCell>#</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id Tabla</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Id Lote</DataTableHeadCell>
                          <DataTableHeadCell alignEnd>Nombre de Tabla</DataTableHeadCell>
                          <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                        </DataTableRow>
                      </DataTableHead>
                      <DataTableBody>
                        {
                          allTables.length > 0
                            ? allTables.map((row, i) => (
                              <DataTableRow key={i}>
                                <DataTableCell alignMiddle={true}>{i + 1}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idTable}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.idLot}</DataTableCell>
                                <DataTableCell alignMiddle={true}>{row.nameTable}</DataTableCell>
                                <DataTableCell alignMiddle={true}>
                                  <Button outlined onClick={() => {
                                    setRedirectBed(true);
                                    setIdTable(row.idTable);
                                  }}>
                                    Ver Camas</Button>
                                  <Button outlined onClick={() => {
                                    editTable(row.idTable);
                                    setIdTable(row.idTable);
                                    setOpen(true);
                                    setEdit(true);
                                  }}>Modificar</Button>
                                  <Button danger outlined onClick={() => {
                                    setOpenConfirmation(true);
                                    setIdTable(row.idTable);
                                  }}>Eliminar</Button>
                                </DataTableCell>
                              </DataTableRow>
                            )) : (
                              <DataTableRow>
                                <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                              </DataTableRow>
                            )
                        }
                      </DataTableBody>
                    </DataTableContent>
                  </DataTable>
                </GridCell>
              </GridRow>
            </Elevation>
          </Card>
        </GridCell>
        <GridCell desktop={2} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar tabla' : 'Crear Tabla'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Nombre de la Tabla'}
                  value={nameTable} onChange={(e) => setNameTable(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          {edit
            ?
            <DialogButton onClick={() => {
              sendValuesEdit(nameTable);
            }} action={'accept'}>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addTable();
            }} action={'accept'}>
              Guardar
            </DialogButton>}
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Tabla"
        body="Esta seguro que quiere eliminar esta Tabla"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteTable(idTable);
          }
          setOpenConfirmation(false);
        }}
      />
      <SimpleDialog
        title= "Error"
        body="Esta Finca tienes datos internos, No se puede eliminar"
        open={respDell}
        onClose={evt => {
          if(evt.detail.action === 'accept'){
            setRespDell(false);
          }
          setRespDell(false);
        }}
      />
    </>
  );
};

export default TableFarm;
