import React from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/Toolbar';
import SiteBody from '../mdc/SiteBody';
import MyComponent from '../MyComponent';
import Footer from './Footer';

const Home = () => {
  return (
    <>
      <DrawerWrapper/>
      <Toolbar/>
      <SiteBody>
        <MyComponent/>
      </SiteBody>
      <Footer/>
    </>
  );
};

export default Home;
