import React from 'react';
import PropTypes from 'prop-types';

const SiteBody = ({ children }) => {
  return (
    <div className={'mdc-body'}>
      {children}
    </div>
  );
};

SiteBody.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default SiteBody;
