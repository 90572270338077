import {api, escalateError, getResponseData} from './index';

export default class ApplicationsApi {

  //Applications
  static getApplications() {
    return api.get('/applications')
      .then(getResponseData)
      .catch(escalateError);
  }

  //Trae solo una aplicacion
  static getApplicationId(idApp) {
    return api.get(`/applications/id/${idApp}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  //Traer los priductos de tipo nutrición
  static getProductsNutritions() {
    return api.get('/nutritions')
      .then(getResponseData)
      .catch(escalateError);
  }

  //Traer los priductos de tipo protección
  static getProductsProtections() {
    return api.get('/protections')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getFarms() {
    return api.get('/farms')
      .then(getResponseData)
      .catch(escalateError);
  }

  static postApplication(data) {
    return api.post('/applications/create', {
      idEmployee: data.employee,
      type: data.typeProduct,
      idProductNP: data.idProduct,
      variety: data.variety,
      numberBeds: data.numBeds,
      farm: data.idFarm,
      litresWater: data.litresW,
      dose: data.dose,
    })
      .then(getResponseData)
      .catch(escalateError);
  }


  static updateApplication(id, data) {
    console.log(data);
    return api.put(`/applications/update/${id}`, {
      idEmployee: data.employee,
      type: data.typeProduct,
      idProductNP: data.idProduct,
      variety: data.variety,
      numberBeds: data.numBeds,
      farm: data.idFarm,
      litresWater: data.litresW,
      dose: data.dose,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static deleteApplication(idApp) {
    return api.delete(`/applications/delete/${idApp}`)
      .then(getResponseData)
      .catch(escalateError);
  }
}
