import {api, escalateError, getResponseData} from './index';

export default class InvoiceApi {

  static getInvoices() {
    return api.get('/cashReceipts')
      .then(getResponseData)
      .catch(escalateError);
  }

  static postInvoice(data) {
    return api.post('/cashReceipts/create', {
      idProvider: data.selectIdProvider,
      description: data.description,
      count: data.cant,
      unitPrice:data.unitPrice,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static getOneInvoice(id){
    return api.get(`/cashreceipts/${id}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateInvoice(id,data){
    return api.put(`/cashreceipts/update/${id}`, {
      idProvider: data.selectIdProvider,
      description: data.description,
      count: data.cant,
      unitPrice:data.unitPrice,
    }).then(getResponseData)
      .catch(escalateError);
  }

  static deleteInvoice(idCash){
    return api.delete(`/cashreceipts/delete/${idCash}`).then(getResponseData).catch(escalateError);
  }
}
