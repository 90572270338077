import React, {useEffect, useState} from "react";
import Toolbar from "../../mdc/Toolbar";
import DrawerWrapper from "../../mdc/drawer/DrawerWrapper";
import SiteBody from "../../mdc/SiteBody";
import Footer from "../Footer";
import {Grid, GridCell, GridRow} from "@rmwc/grid";
import {Card} from "@rmwc/card";
import EmployeeApi from "../../../api/EmployeeApi";

const Profile = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [roleEmpl, setRoleEmpl] = useState('');
  const [status, setStatus] = useState('');
  const [user, setUser] = useState('');

  useEffect(() => {
    getDataClientById(localStorage.getItem('idEmple'));
  }, []);


  const getDataClientById = (id) => {
    EmployeeApi.getOnlyUser(id).then((resp) => {
      console.log(resp.result[0]);
      const {email, name, phone, role, status, user} = resp.result[0];
      setName(name);
      setEmail(email);
      setPhone(phone);
      setRoleEmpl(role);
      setStatus(status);
      setUser(user);
    });
  };

  return (<>
    <DrawerWrapper/>
    <Toolbar/>
    <SiteBody>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={8} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <h2>Usuario</h2>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <div style={{textAlign: 'center', float: 'right'}}>
                  <img
                    src={`https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/220px-User_icon_2.svg.png`}
                    width="100%" alt={'User Profile'}/>
                </div>
                <p><strong>Nombre de Empleado:</strong> {name}</p>
                <p><strong>Nombre de Usuario:</strong> {user}</p>
                <p><strong>Rol:</strong> {roleEmpl}</p>
                <p><strong>Email:</strong> {email}</p>
                <p><strong>Celular:</strong> {phone}</p>
                <p><strong>Estado:</strong> {status}</p>
              </GridCell>
            </GridRow>
            <br/>
          </Card>
        </GridCell>
        <GridCell desktop={1} tablet={12} phone={12}/>
      </Grid>
    </SiteBody>
    <Footer/>
  </>);
};

export default Profile;
