import {api, escalateError, getResponseData} from './index';

export default class DiseaseApi {

  //Diseases
  static getDiseases() {
    return api.get('/diseases')
      .then(getResponseData)
      .catch(escalateError);
  }

  //Trae las enfermedades de los cultivos actualmente sembrados
  static getDiseasesSowns() {
    return api.get('/diseases/sowns')
      .then(getResponseData)
      .catch(escalateError);
  }

  //Trae la enfermedad por id
  static getDisease(idDisease) {
    return api.get(`/diseases/${idDisease}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  //MOdificar una enfermedad necesita el id de enfermedad
  static updateDisease(idDisease, data) {
    console.log(data);
    return api.put(`/diseases/updateData/${idDisease}`,{
      sown: data.numSown,
      diagnosis: data.diagnostic,
      applicationProduct: data.idProduct,
      dose: data.doses,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  //Eliminar una enfermedad
  static deleteDisease(idDisease) {
    console.log('id', idDisease);
    return api.delete(`/diseases/delete/${idDisease}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static createOnlyDisease(dataDisease) {
    console.log(dataDisease);
    return api.post('/diseases/create/', {
        sown: dataDisease.numSown,
        diagnosis: dataDisease.diagnostic,
        applicationProduct: dataDisease.idProduct,
        dose: dataDisease.doses,
      }
    )
      .then(getResponseData)
      .catch(escalateError);
  }


  //Create disease only IMAGE + ID
  static createDiseaseIMG(idDisease, formdata) {
    console.log(idDisease);
    return api.post(`diseases/insertImage/${idDisease}-12qwe`,
      formdata,
    )
      .then(getResponseData)
      .catch(escalateError);
  }
}
