import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import DiseaseApi from "../../../api/DiseaseApi";
import {Select} from "@rmwc/select";
import {Switch} from "@rmwc/switch";
import SownApi from "../../../api/SownApi";
import ApplicationsApi from "../../../api/ApplicationsApi";

const Disease = () => {
  const [openD, setOpenA] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [listDiseases, setListDiseases] = useState([]);
  const [listSown, setListSown] = useState([]);
  const [dateDisease, setDateDisease] = useState('');
  const [listProduct, setListProduct] = useState([]);
  const [typeProduct, setTypeProduct] = useState('');
  const [idDisease, setIdDisease] = useState('');
  const [idDiseaseIMG, setIdDiseaseIMG] = useState('');
  const [numSown, setNumSown] = useState('');
  const [idProduct, setIdProduct] = useState('');
  const [diagnostic, setDiagnostic] = useState('');
  const [doses, setDoses] = useState('');
  const [imageD, setImageD] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    getAllDisease();
    getAllSowns();
  }, []);

  useEffect(() => {
    setListProduct([]);
    if (typeProduct === 'protections') {
      ApplicationsApi.getProductsProtections().then((resp) => {
        resp.result.forEach((elem) => {
          const {idProtection, nameProduct} = elem
          setListProduct((oldArray) => [...oldArray,
            {value: nameProduct, label: `id:${idProtection}- ${nameProduct}`}]);
        });
      });
    }
    if (typeProduct === 'nutritions') {
      ApplicationsApi.getProductsNutritions().then((resp) => {
        resp.result.forEach((elem) => {
          const {idNutrition, nameProduct} = elem
          setListProduct((oldArray) => [...oldArray,
            {value: nameProduct, label: `id:${idNutrition}- ${nameProduct}`}]);
        });
      });
    }
  }, [typeProduct]);

  const getAllSowns = () => {
    SownApi.getSowns().then((resp) => {
      resp.result.forEach((sown) => {
        const {idSown, variety} = sown;
        setListSown((oldArray) => [...oldArray,
          {value: idSown, label: `Id-${idSown} - ${variety}`}]);
      });
    });
  };

  const getAllDisease = () => {
    DiseaseApi.getDiseases().then((resp) => {
      setListDiseases(resp.result);
      console.log(resp.result);
    });
  };

  const deleteDisease = (idDisease) => {
    console.log('llego a eliminar', idDisease);
    DiseaseApi.deleteDisease(idDisease).then((resp) => {
      console.log(resp);
      setListDiseases([]);
      setTimeout(() => {
        console.log('traer la lista');
        DiseaseApi.getDiseases().then((resp) => {
          setListDiseases(resp.result);
          console.log(resp.result);
        });
      }, 3000);
    });
  };

  const setDataFields = () => {
    setDateDisease('');
    setNumSown('');
    setDiagnostic('');
    setDoses('');
    setIdProduct('');
    setImageD('');
  };

  const addDisease = () => {
    if (!imageD) {
      alert('Debe seleccionar una imagen');
      return
    }
    const formdata = new FormData();
    formdata.append('image', imageD);
    const data = {
      dateDisease,
      numSown,
      diagnostic,
      idProduct,
      doses,
      imageD,
    };
    DiseaseApi.createOnlyDisease(data).then((resp) => {
      if (resp.success && resp.idDisease) {
        DiseaseApi.createDiseaseIMG(resp.idDisease, formdata).then(() => {
          setListDiseases([]);
          setDataFields();
          getAllDisease();
        });
      }
    });

  };

  const getDiseaseId = (idD) => {
    DiseaseApi.getDisease(idD).then(resp => {
      const {date, diagnosis, dose, applicationProduct, sown, images} = resp.result[0];
      setDateDisease(date);
      setNumSown(`${sown}`);
      setDiagnostic(diagnosis);
      setDoses(dose);
      setIdProduct(`${applicationProduct}`);
      setIdDiseaseIMG(images);
    });
  };

  const createOnlyImage = (idDisease) => {
    if (!imageD) {
      alert('Debe seleccionar una imagen');
      return
    }
    const formdata = new FormData();
    formdata.append('image', imageD);
    DiseaseApi.createDiseaseIMG(idDisease, formdata).then(() => {
      setListDiseases([]);
      setDataFields();
      getAllDisease();
    });
  };

  const saveEditDise = (idD, checked) => {
    console.log(idD, checked);
    const data = {
      dateDisease,
      numSown,
      diagnostic,
      idProduct,
      doses,
    };
    if(checked) createOnlyImage(idD);
    DiseaseApi.updateDisease(idD, data).then((resp) => {
      console.log(resp);
      setListDiseases([]);
      getAllDisease();
      setDataFields();
      setChecked(false);
    });
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={7} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <h1>CAMSAL</h1>
                </div>
                <div className={'title-camsal'}>
                  Enfermedades
                </div>
              </GridCell>
              <GridCell align={'middle'} desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Registrar Enfermedad'} onClick={() => {
                    if (diagnostic.length > 0) setDataFields();
                    setOpenA(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>ID</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>ID Enfermedad</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Fecha</DataTableHeadCell>
                        <DataTableHeadCell alignEnd># Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Diagnostico</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Producto Aplicado</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Imagen</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listDiseases.length > 0
                          ? listDiseases.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}><b>{i + 1}</b></DataTableCell>
                              <DataTableCell align={'right'}>{row.idDisease}</DataTableCell>
                              <DataTableCell align={'right'}>{row.date}</DataTableCell>
                              <DataTableCell align={'right'}>{row.sown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.diagnosis}</DataTableCell>
                              <DataTableCell align={'right'}>{row.applicationProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.dose}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button raised onClick={() => {
                                  setOpenImage(true);
                                  if (idDiseaseIMG.length > 0) setIdDisease('');
                                  getDiseaseId(row.idDisease);
                                }}>
                                  Imagen
                                </Button>
                              </DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpenA(true);
                                  setIdDisease(row.idDisease);
                                  getDiseaseId(row.idDisease);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setOpenConfirmation(true);
                                  setIdDisease(row.idDisease);
                                }}>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No Carga</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
          </Card>
        </GridCell>
      </Grid>
      <Dialog
        open={openImage}
        onClose={() => {
          setOpenImage(false);
        }}
      >
        <DialogTitle>Imagen de la Enfermedad</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12}>
                <img src={`http://controler.com.co/camsal-back/${idDiseaseIMG}`} width="100%" alt={'Disease'}/>
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Back</DialogButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openD}
        onClose={() => {
          setOpenA(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Enfermedad' : 'Crear Enfermedad'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Id de siembra'}
                  options={listSown || []}
                  value={numSown}
                  disabled={listSown.length < 1}
                  onChange={(e) => setNumSown(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={12} tablet={12} phone={12}>
                <TextField
                  textarea
                  outlined
                  fullwidth
                  rows={2}
                  maxLength={50}
                  characterCount
                  label={'Diagnostico'}
                  value={diagnostic} onChange={(e) => setDiagnostic(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={12} tablet={12} phone={12}>
                <p>¿ Qué se le aplicó ? </p>
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Tipo de Producto'}
                  options={['nutritions', 'protections']}
                  value={typeProduct}
                  disabled={listSown.length < 1}
                  onChange={(e) => {
                    setTypeProduct(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre de Producto'}
                  options={listProduct || []}
                  disabled={listProduct.length < 1}
                  value={idProduct}
                  onChange={(e) => setIdProduct(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={12} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Dosis'}
                  value={doses} onChange={(e) => setDoses(e.target.value)}
                />
              </GridCell>
              {edit
                ? <div style={{width: '5rem', height: '5rem', marginBottom: '15px'}}>
                  <img src={`http://controler.com.co/camsal-back/${idDiseaseIMG}`} width="100%" alt={'Disease'}/>
                </div>
                : ''}
              <br/><br/>
              <GridCell desktop={12} tablet={12} phone={12}>
                <br/><br/>
                <Switch
                  checked={checked}
                  onChange={evt => setChecked(!!evt.currentTarget.checked)}
                  label="Desea cambiar la imagen? "
                />
              </GridCell>
              {checked
              ? <GridCell desktop={12} tablet={12} phone={12}>
                  <TextField
                    type={'file'}
                    onChange={(e) => {
                      setImageD(e.target.files[0])
                    }}
                  />
                </GridCell>
              : ''}
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              saveEditDise(idDisease,checked);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton action={'accept'} isDefaultAction onClick={() => {
              addDisease();
            }}>
              Guardar
            </DialogButton>
          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Enfermedad"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteDisease(idDisease);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Disease;
