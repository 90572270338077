import {api, escalateError, getResponseData} from './index';

export default class FarmApi {

  //FARMS
  static getFarms() {
    return api.get('/farms')
      .then(getResponseData)
      .catch(escalateError);
  }

  static postData(name) {
    return api.post('farms/create', {nameFarm: name})
      .then(getResponseData)
      .catch(escalateError);
  }

  static getFarm(idFarm) {
    return api.get(`/farms/${idFarm}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateFarm(idFarm, nameF) {
    return api.put(`/farms/update/${idFarm}`, {
      nameFarm: nameF,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static deleteFarm(idFarm) {
    return api.delete(`/farms/delete/${idFarm}`)
      .then(getResponseData)
      .catch(escalateError);
  }


  //LOTS
  static getLots() {
    return api.get('/lots')
      .then(getResponseData)
      .catch(escalateError);
  }

  static postLot(idFarm, nameLot) {
    return api.post('/lots/create', {
      idFarm: idFarm,
      nameLot: nameLot
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static getLot(idLot) {
    return api.get(`/lots/${idLot}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateLot(idLot, idFarm, nameLot) {
    return api.put(`/lots/update/${idLot}`, {
      idFarm: idFarm,
      idLot: idLot,
      nameLot: nameLot,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static deleteLot(idLot) {
    return api.delete(`/lots/delete/${idLot}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getLotsFarm(idFarm) {
    return api.get(`/lots/farm/${idFarm}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  //TABLES
  static getTables() {
    return api.get('/tables')
      .then(getResponseData)
      .catch(escalateError);
  }

  static postTable(idLot, name) {
    return api.post('/tables/create', {
      idLot: idLot,
      nameTable: name
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static getTable(idTable) {
    return api.get(`/tables/${idTable}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateTable(idTable, idLot, nameTable) {
    return api.put(`/tables/update/${idTable}`, {
      idTable: idTable,
      idLot: idLot,
      nameTable: nameTable,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static deleteTable(idTable) {
    return api.delete(`/tables/delete/${idTable}`,)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getTablesLot(idLot) {
    return api.get(`/tables/lot/${idLot}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  // BEDS
  static getBeds() {
    return api.get('/beds')
      .then(getResponseData)
      .catch(escalateError);
  }

  static postBed(idTable, nameBed, countBed, typeBed) {
    return api.post('/beds/create', {
      idTable: idTable,
      nameBed: nameBed,
      type: typeBed,
      areaBed: 60,
      count: countBed,
      countAvailable: countBed,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  //obtener una sola cama falta el id de la cama
  static getBed(idBed) {
    return api.get(`/beds/${idBed}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  //para modificar una cama hace falta el id y los datos de la cama
  static updateBed(idTable,idBed, nameBed, countBed, typeBed) {
    return api.put(`/beds/update/${idBed}`, {
      idTable: idTable,
      nameBed: nameBed,
      areaBed: 60,
      type: typeBed,
      count: countBed,
      countAvailable: countBed,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static deleteBed(idBed) {
    return api.delete(`/beds/delete/${idBed}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getBedTable(idLot) {
    return api.get(`/beds/table/${idLot}`)
      .then(getResponseData)
      .catch(escalateError);
  }
}
