import React from 'react';

const Footer = () => {
  return (
    <footer className={'footer text-center'}>
      <div style={{textAlign:"center"}}>Copyright © Your Website 2021 &nbsp;
        <a href={'https://camsal-test.netlify.app/dashboard.html'}>Controler</a>.
      </div>
    </footer>
  );
};
export default Footer;
