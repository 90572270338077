import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import HarvestApi from "../../../api/HarvestApi";
import {Select} from "@rmwc/select";
import EmployeeApi from "../../../api/EmployeeApi";
import SownApi from "../../../api/SownApi";

const Harvest = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [listHarvest, setListHarvest] = useState([]);
  const [listSown, setListSown] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const [weekNum, setWeekNum] = useState('');
  const [variety, setVariety] = useState('');
  const [sownCant, setSownCant] = useState('');
  const [harvestCant, setHarvestCant] = useState('');
  const [crateCant, setCrateCant] = useState('');
  const [idHarv, setHar] = useState('');
  const [kg, setKg] = useState('');
  const [responsible, setResponsible] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    getAllHarvest();
    getAllEmployees();
    getAllSowns();
  }, []);

  const getAllHarvest = () => {
    HarvestApi.getHarvest().then((resp) => {
      setListHarvest(resp.result);
    });
  };

  const getAllEmployees = () => {
    EmployeeApi.getEmployees().then((resp) => {
      resp.result.forEach((employee) => {
        const {idEmployee, name} = employee;
        setListEmployee((oldArray) => [...oldArray,
          {value: idEmployee, label: name}]);
      });
    });
  };

  const getAllSowns = () => {
    SownApi.getSowns().then((resp) => {
      resp.result.forEach((sown) => {
        const {idSown, variety} = sown;
        setListSown((oldArray) => [...oldArray,
          {value: idSown, label: `Id-${idSown} - ${variety}`}]);
      });
    });
  };

  const addNewHarvest = () => {
    const data = {
      weekNum,
      variety,
      sownCant,
      harvestCant,
      crateCant,
      kg,
      responsible,
      price,
    };
    HarvestApi.createHarvest(data).then(() => {
      setListHarvest([]);
      getAllHarvest();
      setDataFields();
    });
  };

  const setDataFields = () => {
    setWeekNum('');
    setVariety('');
    setSownCant('');
    setHarvestCant('');
    setCrateCant('');
    setKg('');
    setResponsible('');
    setPrice('');
  };

  const editHarvest = (idhaer) => {
    const data = {
      weekNum,
      variety,
      sownCant,
      harvestCant,
      crateCant,
      kg,
      responsible,
      price,
    };
    HarvestApi.updateHarvest(idhaer, data).then((resp) => {
      console.log(resp);
      setListHarvest([]);
      getAllHarvest();
      setDataFields();
    });
  };

  const getDataToEdit = (idHar) => {
    HarvestApi.getIdHarvest(idHar).then((resp) => {
      console.log(resp.result[0]);
      const {
        week,
        price,
        quantityKg,
        responsible,
        quantityCrate,
        quantityHarvest,
        quantitySown,
        variety
      } = resp.result[0];
      console.log('variaty', variety);
      setWeekNum(week);
      setVariety(`${variety}`);
      setSownCant(quantitySown);
      setHarvestCant(quantityHarvest);
      setCrateCant(quantityCrate);
      setKg(quantityKg);
      setResponsible(`${responsible}`);
      setPrice(price);
    });
  };

  const deleteHarvest = (idHarvest) => {
    HarvestApi.deleteHarvest(idHarvest).then(() => {
      setListHarvest([]);
      getAllHarvest();
    })
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={8} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Lista de Cosechas
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Nueva Cosecha'} onClick={() => {
                    if (sownCant.length > 0) setDataFields();
                    setOpen(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>ID</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>ID Cosecha</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Semana</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Fecha de cosecha</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Variedad</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad Sembrada</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad de Canastillas</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad en Cosecha</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad Kg</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Responsable</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Precio $</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listHarvest.length > 0
                          ? listHarvest.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}><strong>{i + 1}</strong></DataTableCell>
                              <DataTableCell align={'right'}><strong>{row.idHarvest}</strong></DataTableCell>
                              <DataTableCell align={'right'}>{row.week}</DataTableCell>
                              <DataTableCell align={'right'}>{row.harvestDate}</DataTableCell>
                              <DataTableCell align={'right'}>{row.variety}</DataTableCell>
                              <DataTableCell align={'right'}>{row.quantitySown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.quantityCrate}</DataTableCell>
                              <DataTableCell align={'right'}>{row.quantityHarvest}</DataTableCell>
                              <DataTableCell align={'right'}>{row.quantityKg}</DataTableCell>
                              <DataTableCell align={'right'}>{row.responsible}</DataTableCell>
                              <DataTableCell align={'right'}>{row.price}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpen(true);
                                  setHar(row.idHarvest);
                                  getDataToEdit(row.idHarvest);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setOpenConfirmation(true);
                                  //deleteHarvest(row.idHarvest);
                                }}>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No Carga</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
            <br/>
          </Card>
        </GridCell>
        <GridCell desktop={1} tablet={12} phone={12}/>
      </Grid>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{edit ? ' Editar Cosecha' : 'Crear Cosecha'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined
                  label={'Semana de Cosecha'}
                  value={weekNum} onChange={(e) => setWeekNum(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Id de siembra'}
                  options={listSown || []}
                  value={variety}
                  disabled={listSown.length < 1}
                  onChange={(e) => setVariety(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cantidad Sembrada'}
                  value={sownCant} onChange={(e) => setSownCant(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cantidad Cosecha'}
                  value={harvestCant} onChange={(e) => setHarvestCant(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cantidad de Canastillas'}
                  value={crateCant} onChange={(e) => setCrateCant(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cantidad en Kg'}
                  value={kg} onChange={(e) => setKg(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre del Empleado'}
                  options={listEmployee || []}
                  value={responsible}
                  disabled={listEmployee.length < 1}
                  onChange={(e) => setResponsible(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Precio'}
                  value={price} onChange={(e) => setPrice(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'} onClick={() => {
          }}>Cancelar</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              editHarvest(idHarv);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addNewHarvest();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>

          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Producto"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteHarvest(idHarv);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Harvest;
