import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import ProductsApi from "../../../../api/ProductsApi";
import {Link} from "react-router-dom";
import {Select} from "@rmwc/select";

const Protection = () => {
  const [openD, setOpenA] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [listProtection, setListProtection] = useState([]);
  const [idProtection, setIdProtection] = useState('');
  const [nameProduct, setNameProduct] = useState([]);
  const [selectNamePrd, setSelectNamePrd] = useState('');
  const [typeP, setTypeP] = useState('');
  const [purpose, setPurpose] = useState('');
  const [volHectare, setVolHectare] = useState(0);
  const [doseHectare, setDoseHectare] = useState('');
  const [volumeSown, setVolumeSown] = useState(0);
  const [doseDown, setDoseDown] = useState('');
  const [volumeLitre, setVolumeLitre] = useState(0);
  const [doseLitre, setDoseLitre] = useState('');
  const [periodReEntry, setPeriodReEntry] = useState('');
  const [protective, setProtective] = useState('');
  const [erradicant, setErradicant] = useState('');
  const [limitMaxResidue, setLimitMaxResidue] = useState('');
  const [typeAplic, setTypeAplic] = useState('');

  useEffect(() => {
    getAllProtection();
  }, []);

  const getAllProtection = () => {
    ProductsApi.getProductsProtections().then((resp) => {
      setListProtection(resp.result);
      ProductsApi.getProductsProtectionsName().then((res) =>{
        res.result.forEach((prodPro) => {
          const {nameProduct} = prodPro;
          setNameProduct((oldArray) => [...oldArray, nameProduct]);
        });
      });
    });
  };

  const addProtection = () => {
    const data = {
      selectNamePrd,
      typeP,
      purpose,
      volHectare,
      doseHectare,
      volumeSown,
      doseDown,
      volumeLitre,
      doseLitre,
      periodReEntry,
      protective,
      erradicant,
      limitMaxResidue,
      typeAplic,
    };
    ProductsApi.createProductProtection(data).then(() => {
      setListProtection([]);
      setNameProduct([]);
      getAllProtection();
      setDateFields();
    });
  };

  const setDateFields = () => {
    setSelectNamePrd('');
    setTypeP('');
    setPurpose('');
    setVolHectare(0);
    setDoseHectare('');
    setVolumeSown(0);
    setDoseDown('');
    setVolumeLitre(0);
    setDoseLitre('');
    setPeriodReEntry('');
    setProtective('');
    setErradicant('');
    setLimitMaxResidue('');
    setTypeAplic('');
  };

  const deleteProtection = (id) => {
    ProductsApi.deleteProductProtection(id).then(()=>{
      setListProtection([]);
      getAllProtection();
    });
  };

  const getDataTOEdit = (idPro) => {
    ProductsApi.getProductIdProtection(idPro).then((resp) => {
      console.log(resp.result[0]);
      const {
        doseHectare,
        doseLitre,
        doseSown,
        erradicant,
        limitMaxResidue,
        nameProduct,
        periodReEntry,
        protective,
        purpose,
        type,
        typeProduct,
        volumeHectare,
        volumeLitre,
        volumeSown
      } = resp.result[0];
      setSelectNamePrd(nameProduct);
      setTypeP(`${type}`);
      setPurpose(purpose);
      setVolHectare(volumeHectare);
      setDoseHectare(doseHectare);
      setVolumeSown(volumeSown);
      setDoseDown(doseSown);
      setVolumeLitre(volumeLitre);
      setDoseLitre(doseLitre);
      setPeriodReEntry(periodReEntry);
      setProtective(protective);
      setErradicant(erradicant);
      setLimitMaxResidue(limitMaxResidue);
      setTypeAplic(typeProduct);
    });
  };

  const editProtection = (idPro) => {
    const data = {
      selectNamePrd,
      typeP,
      purpose,
      volHectare,
      doseHectare,
      volumeSown,
      doseDown,
      volumeLitre,
      doseLitre,
      periodReEntry,
      protective,
      erradicant,
      limitMaxResidue,
      typeAplic,
    };
    ProductsApi.updateProductProtection(idPro, data).then(() => {
      setListProtection([]);
      setNameProduct([]);
      getAllProtection();
      setDateFields();
    });
  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Lista de Insumos de Protección
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Crear Prd Proteccion'} onClick={() => {
                    if (purpose.length > 0) setDateFields();
                    setOpenA(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Link to={'/products'}>
                    <div className={'title-camsal'}>
                      <Button label={'Productos'}/>
                    </div>
                  </Link>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <h6><a href="https://irac-online.org/" target={'_blank'}>IRAC</a></h6>
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <h6><a href="https://www.frac.info/" target={'_blank'}>FRAC</a></h6>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>#</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Id</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Ingrediente Activo</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Proposito</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Blanco Biologico</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Periodo de Entrada</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Periodo de Salida</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis por Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen por litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis por litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Limite de residuo</DataTableHeadCell>
                        <DataTableHeadCell alignMiddle>Opciones</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listProtection.length > 0
                          ? listProtection.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}>{i + 1}</DataTableCell>
                              <DataTableCell align={'right'}>{row.idProtection}</DataTableCell>
                              <DataTableCell align={'right'}>{row.nameProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.type}</DataTableCell>
                              <DataTableCell align={'right'}>{row.activeIngredient}</DataTableCell>
                              <DataTableCell align={'right'}>{row.purpose}</DataTableCell>
                              <DataTableCell align={'right'}>{row.biologicalWhite}</DataTableCell>
                              <DataTableCell align={'right'}>{row.periodReEntry}</DataTableCell>
                              <DataTableCell align={'right'}>{row.periodLack}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.limitMaxResidue}</DataTableCell>
                              <DataTableCell align={'right'}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpenA(true);
                                  setIdProtection(row.idProtection);
                                  getDataTOEdit(row.idProtection);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setOpenConfirmation(true);
                                  setIdProtection(row.idProtection);
                                }}>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>No Carga</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
          </Card>
        </GridCell>
      </Grid>
      <Dialog
        open={openD}
        onClose={() => {
          setOpenA(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Prd. Protección' : 'Crear Prd. Protección'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre Producto'}
                  options={nameProduct || []}
                  value={selectNamePrd}
                  disabled={nameProduct.length < 1}
                  onChange={(e) => setSelectNamePrd(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Tipo de Producto'}
                  options={['Plaguicidas', 'Fungicidas',
                    'Insecticidas', 'Acaricidas', 'Herbicidas']}
                  value={typeP}
                  disabled={selectNamePrd.length < 1}
                  onChange={(e) => {
                    setTypeP(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Proposito'}
                  value={purpose} onChange={(e) => setPurpose(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Volumen Hectarea'}
                  value={volHectare} onChange={(e) => setVolHectare(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Dosis Hectarea'}
                  value={doseHectare} onChange={(e) => setDoseHectare(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Volumen por Siembra'}
                  value={volumeSown} onChange={(e) => setVolumeSown(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Dosis por Siembra'}
                  value={doseDown} onChange={(e) => setDoseDown(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Volumen por litro'}
                  value={volumeLitre} onChange={(e) => setVolumeLitre(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Dosis por Litro'}
                  value={doseLitre} onChange={(e) => setDoseLitre(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Periodo Re-Entrada'}
                  value={periodReEntry} onChange={(e) => setPeriodReEntry(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Protector'}
                  value={protective} onChange={(e) => setProtective(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Erradicante'}
                  value={erradicant} onChange={(e) => setErradicant(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Limit-Maximo-Residuo'}
                  value={limitMaxResidue} onChange={(e) => setLimitMaxResidue(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Unidad de medida Producto:'}
                  options={['Litros', 'Gramos']}
                  value={typeAplic}
                  disabled={selectNamePrd.length < 1}
                  onChange={(e) => {
                    setTypeAplic(e.target.value);
                  }}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              editProtection(idProtection);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addProtection();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>
          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Producto de Protección"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deleteProtection(idProtection);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Protection;
