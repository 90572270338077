import React, {useEffect, useState} from 'react';
import DrawerWrapper from '../../../mdc/drawer/DrawerWrapper';
import Toolbar from '../../../mdc/Toolbar';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {Card} from '@rmwc/card';
import {
  DataTable, DataTableCell, DataTableContent,
  DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody
} from '@rmwc/data-table';
import {Button} from '@rmwc/button';
import {Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog} from '@rmwc/dialog';
import {TextField} from '@rmwc/textfield';
import ProductsApi from "../../../../api/ProductsApi";
import {Select} from "@rmwc/select";
import {Link} from "react-router-dom";

const Nutrition = () => {
  const [openD, setOpenA] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [idNutrition, setIdNutrition] = useState('');
  const [listNutri, setListNutri] = useState([]);
  const [nameProduct, setNameProduct] = useState([]);
  const [selectNamePrd, setSelectNamePrd] = useState('');
  const [typeProd, setTypeProd] = useState('');
  const [composition, setComposition] = useState('');
  const [purpose, setPurpose] = useState('');
  const [volHectare, setVolHectare] = useState(0);
  const [doseHectare, setDoseHectare] = useState('');
  const [volumeSown, setVolumeSown] = useState(0);
  const [doseDown, setDoseDown] = useState('');
  const [volumeLitre, setVolumeLitre] = useState(0);
  const [doseLitre, setDoseLitre] = useState('');
  const [categoryToxicology, setCategoryToxicology] = useState('')
  const [typeAplic, setTypeAplic] = useState('');
  const [cant, setCant] = useState(0);

  useEffect(() => {
    getAllNutrition();
  }, []);

  const getAllNutrition = () => {
    ProductsApi.getProductsNutritions().then((resp) => {
      setListNutri(resp.result);
      ProductsApi.getProductsNutritionName().then((res)=>{
        res.result.forEach((prodNutr) => {
          const {nameProduct} = prodNutr;
          setNameProduct((oldArray) => [...oldArray, nameProduct]);
        });
      })
    });
  };

  const addPrdNutrition = () => {
    const data = {
      selectNamePrd,
      typeProd,
      composition,
      purpose,
      volHectare,
      doseHectare,
      volumeSown,
      doseDown,
      volumeLitre,
      doseLitre,
      categoryToxicology,
      typeAplic,
      cant,
    };
    ProductsApi.createProductNutrition(data).then(() => {
      setListNutri([]);
      setNameProduct([]);
      getAllNutrition();
      setDataFields();
    })
  };

  const setDataFields = () => {
    setSelectNamePrd('');
    setTypeProd('');
    setComposition('');
    setPurpose('');
    setVolHectare(0)
    setDoseHectare('');
    setVolumeSown(0);
    setDoseDown('');
    setVolumeLitre(0);
    setDoseLitre('');
    setCategoryToxicology('');
    setTypeAplic('');
    setCant(0);
  };

  const deletePrdNutrition = (idNutrition) => {
    ProductsApi.deleteProductNutrition(idNutrition).then(() => {
      setListNutri([]);
      getAllNutrition();
    })
  };

  const getDataTOEdit = (idNu) => {
    ProductsApi.getProductIdNutritions(idNu).then((resp) => {
      const {
        nameProduct, type, purpose, volumeHectare, doseHectare, composition,
        volumeSown, doseSown, volumeLitre, doseLitre, categoryToxicology, typeProduct, count
      } = resp.result[0];
      setSelectNamePrd(nameProduct);
      setTypeProd(`${type}`);
      setComposition(composition);
      setPurpose(purpose);
      setVolHectare(volumeHectare);
      setDoseHectare(doseHectare);
      setVolumeSown(volumeSown);
      setDoseDown(doseSown);
      setVolumeLitre(volumeLitre);
      setDoseLitre(doseLitre);
      setCategoryToxicology(categoryToxicology);
      setTypeAplic(`${typeProduct}`);
      setCant(count);
    });
  };

  const editProdNutrition = (idNutr) => {
    const data = {
      selectNamePrd,
      typeProd,
      composition,
      purpose,
      volHectare,
      doseHectare,
      volumeSown,
      doseDown,
      volumeLitre,
      doseLitre,
      categoryToxicology,
      typeAplic,
      cant,
    };
    ProductsApi.updateProductNutrition(idNutr, data).then(() => {
      setListNutri([]);
      setNameProduct([]);
      getAllNutrition();
      setDataFields();
    });

  };

  return (
    <>
      <DrawerWrapper/>
      <Toolbar useCustomTheme={false}/>
      <Grid>
        <GridCell desktop={1} tablet={12} phone={12}/>
        <GridCell desktop={9} tablet={12} phone={12}>
          <Card outlined>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  Lista de Insumos de Nutrición
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Button raised label={'Crear Insumo'} onClick={() => {
                    if (purpose.length > 0) setDataFields();
                    setOpenA(true);
                    setEdit(false);
                  }}/>
                </div>
              </GridCell>
              <GridCell desktop={2} tablet={12} phone={12}>
                <div className={'title-camsal'}>
                  <Link to={'/products'}>
                    <div className={'title-camsal'}>
                      <Button label={'Productos'}/>
                    </div>
                  </Link>
                </div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell desktop={12} tablet={12} phone={12} align={'middle'}
                        style={{padding: '30px'}}>
                <DataTable
                  style={{height: '400px', width: '100%'}}>
                  <DataTableContent>
                    <DataTableHead>
                      <DataTableRow>
                        <DataTableHeadCell>#</DataTableHeadCell>
                        <DataTableHeadCell>ID</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Nombre del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo del Producto</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Composición</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Proposito</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Hectarea</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Siembra</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Volumen Litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Dosis Litro</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Categoria de Toxicologia</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Tipo de Producto Medición</DataTableHeadCell>
                        <DataTableHeadCell alignEnd>Cantidad</DataTableHeadCell>
                      </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                      {
                        listNutri.length > 0
                          ? listNutri.map((row, i) => (
                            <DataTableRow key={i}>
                              <DataTableCell align={'right'}><b>{i + 1}</b></DataTableCell>
                              <DataTableCell align={'right'}>{row.idNutrition}</DataTableCell>
                              <DataTableCell align={'right'}>{row.nameProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.type}</DataTableCell>
                              <DataTableCell align={'right'}>{row.composition}</DataTableCell>
                              <DataTableCell align={'right'}>{row.purpose}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseHectare}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseSown}</DataTableCell>
                              <DataTableCell align={'right'}>{row.volumeLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.doseLitre}</DataTableCell>
                              <DataTableCell align={'right'}>{row.categoryToxicology}</DataTableCell>
                              <DataTableCell align={'right'}>{row.typeProduct}</DataTableCell>
                              <DataTableCell align={'right'}>{row.count}</DataTableCell>
                              <DataTableCell alignMiddle={true}>
                                <Button outlined onClick={() => {
                                  setEdit(true);
                                  setOpenA(true);
                                  setIdNutrition(row.idNutrition);
                                  getDataTOEdit(row.idNutrition);
                                }}>Modificar</Button>
                                <Button danger outlined onClick={() => {
                                  setOpenConfirmation(true);
                                  setIdNutrition(row.idNutrition);
                                }}>Eliminar</Button>
                              </DataTableCell>
                            </DataTableRow>
                          )) : (
                            <DataTableRow>
                              <DataTableCell align={'right'}>Cargando Datos</DataTableCell>
                            </DataTableRow>
                          )
                      }
                    </DataTableBody>
                  </DataTableContent>
                </DataTable>
              </GridCell>
            </GridRow>
          </Card>
        </GridCell>
      </Grid>
      <Dialog
        open={openD}
        onClose={() => {
          setOpenA(false);
        }}
      >
        <DialogTitle>{edit ? 'Editar Prd. Nutrición' : 'Crear Prd. Nutrición'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridRow>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Nombre Producto'}
                  options={nameProduct || []}
                  value={selectNamePrd}
                  disabled={nameProduct.length < 1}
                  onChange={(e) => setSelectNamePrd(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Tipo de Producto'}
                  options={['Apl. a Suelo', 'Apl. Foliar']}
                  value={typeProd}
                  disabled={selectNamePrd.length < 1}
                  onChange={(e) => {
                    setTypeProd(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Composición'}
                  value={composition} onChange={(e) => setComposition(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Proposito'}
                  value={purpose} onChange={(e) => setPurpose(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Volumen Hectarea'}
                  value={volHectare} onChange={(e) => setVolHectare(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Dosis Hectarea'}
                  value={doseHectare} onChange={(e) => setDoseHectare(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Volumen por Siembra'}
                  value={volumeSown} onChange={(e) => setVolumeSown(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Dosis por Siembra'}
                  value={doseDown} onChange={(e) => setDoseDown(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Volumen por litro'}
                  value={volumeLitre} onChange={(e) => setVolumeLitre(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Dosis por Litro'}
                  value={doseLitre} onChange={(e) => setDoseLitre(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'text'}
                  label={'Categoria Toxicologica'}
                  value={categoryToxicology} onChange={(e) => setCategoryToxicology(e.target.value)}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <Select
                  label={'Cantidad'}
                  options={['Litros', 'Gramos']}
                  value={typeAplic}
                  disabled={selectNamePrd.length < 1}
                  onChange={(e) => {
                    setTypeAplic(e.target.value);
                  }}
                />
              </GridCell>
              <GridCell desktop={6} tablet={12} phone={12}>
                <TextField
                  outlined type={'number'}
                  min={'1'} pattern={'^[0-9]'}
                  label={'Cantidad'}
                  value={cant} onChange={(e) => setCant(e.target.value)}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cancel</DialogButton>
          {edit ?
            <DialogButton onClick={() => {
              editProdNutrition(idNutrition);
            }} action={'accept'} isDefaultAction>
              Editar
            </DialogButton>
            : <DialogButton onClick={() => {
              addPrdNutrition();
            }} action={'accept'} isDefaultAction>
              Guardar
            </DialogButton>
          }
        </DialogActions>
      </Dialog>
      <SimpleDialog
        title="Eliminar Producto de Nutrición"
        body="Esta seguro que quiere eliminar"
        open={openConfirmation}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            deletePrdNutrition(idNutrition);
          }
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default Nutrition;
